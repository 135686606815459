import { useEffect, useState } from "react";
import { advisorAPI } from "../../../../../apis/AdvisorsAPI";
import { fetchCurrentCompany } from "../../../../../actions/company.actions";
import { useDispatch } from "react-redux";
import { Button, Icon, Select } from "semantic-ui-react";
import AddKey from "./AddKey";
import DontMap from "./DontMap";

const TypeConfiguration = ({companyProfile, propType}) => {
  const dispatch = useDispatch();
  const configuration = companyProfile?._integrations?.salesforce;
  const [valuesType, setValuesType] = useState({});
  const options = configuration?.settings?.mapperTypes[propType]?.typesAvailable?.map((t) => ({ key: `${t.value}`, value: `${t.value}`, text: `${t.label}` })) || [];

  options.push({ key: "", value: "", text: "" });

  const handleChangeType = async (prop, value) => {
    try {
      const objectNew = JSON.parse(JSON.stringify(valuesType));
      objectNew[prop] = value;
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.mapperTypes.${propType}.values`]: objectNew,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
    } catch (error) {
      console.log({ error });
      alert("Something it's wrong, try again please...");
    }
  };

  const hendleDeleteProp = async (prop) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(`Are you sure delete this prop: ${prop} ?`);
    if(!confirmation){
      return
    }
    try {
      const valuesObject = JSON.parse(JSON.stringify(configuration?.settings?.mapperTypes[propType]?.values || {}))
      delete valuesObject[prop]
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.settings.mapperTypes.${propType}.values`]: valuesObject,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
    } catch (error) {
      console.log({error})
      alert("Error in delete this prop")
    }
  }
  useEffect(() => {
    setValuesType(configuration?.settings?.mapperTypes[propType]?.values || {});

    // eslint-disable-next-line
  }, [companyProfile]);

  const TITLE = {
    relationship: "Relationship Types",
    assetTypes: "Asset Types",
  }

  return (
    <div className="card-border-line" style={{minWidth:"400px"}}>
      <h3>{TITLE[propType] || "No title..."}</h3>
      <div className="flex-fpalpha gap-4 items-center">
        <div>
          <AddKey settingsType={configuration?.settings?.mapperTypes[propType]} companyProfile={companyProfile} propType={propType} />
        </div>
        <div>
          <DontMap settingsType={configuration?.settings?.mapperTypes[propType]} companyProfile={companyProfile} propType={propType} />
        </div>
      </div>
      <div className="box-scroll" style={{marginTop: "5px"}}>
        {Object.keys(valuesType).map((key, index) => (
          <div key={index} style={{ marginBottom: "10px", background: valuesType[key] ? "#D8FFC9aa" : "#eee", padding: "5px", borderRadius: "10px" }}>
            <Icon name="remove circle" style={{cursor:"pointer", margin: "5px"}} onClick={() => hendleDeleteProp(key)}  /> <strong>{key}</strong>: {options.find((e) => e.value === valuesType[key])?.text}
            <div>
              <Select
                fluid
                options={options}
                defaultValue={valuesType[key]}
                onChange={(e, _) => handleChangeType(key, _.value)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypeConfiguration;
