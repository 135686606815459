import { useEffect, useRef, useState } from "react";
import { sysAPI } from "../../../apis/SysAPI";
import { Button, Form, Modal, TextArea } from "semantic-ui-react";

const ContinueReviewModal = ({ manualInput, clientId, index, hideModal, showModal, refreshList }) => {
    const messageArea = useRef();
    const [message, setMessage] = useState('');
    const [unfilled, setUnfilled] = useState(false);
    const [loader, setLoader] = useState(false)

    const handleMessage = e => {
        setMessage(e.target.value);
    };

    const continueReview = async () => {
        if (!message || message.length <= 5) {
            setUnfilled('You are required to enter a reason for submitting on hold.');
            return true;
        }
        if (unfilled) setUnfilled(false);
        setLoader(true)
        if([4].includes(manualInput.version)) {
            for(const index__ of [1,2]){
                try {
                    const {data} = await sysAPI.post(`/estate-manual-input/continuereview`, {clientId, index: index__, message});
                } catch (error) {
                    console.log({error})
                }
            }
            refreshList(prev => (prev + 1));
            hideModal(null);
        }else{
            try {
                const {data} = await sysAPI.post('/estate-manual-input/continuereview', {clientId, index, message})
                refreshList(prev => (prev + 1));
                hideModal(null);
            } catch (error) {
                const messageError = error?.response?.data?.message || error.message || "Something is wrong"
                console.log({error});
                setUnfilled(messageError)
            }
        }
        setLoader(false)
    };

    useEffect(() => {
        messageArea.current.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Modal open={showModal !== null}>
            <Modal.Header>Why do you want to continue reviewing this E.M.I.R.?</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <TextArea ref={messageArea} value={message} onChange={handleMessage} />
                    </Form.Field>
                </Form>
                <p style={{color: 'red', marginTop: '0.8em'}}>{unfilled || ''}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => hideModal(null)}>Cancel</Button>
                <Button
                    onClick={continueReview}
                    color="purple"
                    loading={loader}
                >
                    Send to hold
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ContinueReviewModal;