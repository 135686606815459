import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import FormSaml from "./Form";
import { sysAPI } from "../../apis/SysAPI";

const CreateSaml = ({ refreshFunction, companies = [] }) => {
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [info, setInfo] = useState({});
  const [loader, setLoader] = useState(false);

  const handleCreate = async () => {
    setLoader(true);
    try {
      //console.log({ info });
      //console.log({info})
      const { data } = await sysAPI.post(`/configs-saml`, { ...info });
      refreshFunction();
      setOpenModal(false);
    } catch (error) {
      console.log({ error });
      alert(error?.response?.data?.message || error.message);
    }
    setLoader(false);
  };

  return (
    <>
      {me?.permissions?.includes("saml:saml_add") && (
        <>
          <Button icon onClick={() => setOpenModal(true)}>
            <Icon name="add" />
          </Button>

          <Modal size="small" open={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header>Create Integration SAML</Modal.Header>
            <Modal.Content>
              <FormSaml info={info} setInfo={setInfo} companies={companies}  />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpenModal(false)}>Cancel</Button>
              <Button onClick={handleCreate} color="green">
                Create
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </>
  );
};

export default CreateSaml;
