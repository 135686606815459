/* eslint-disable no-mixed-operators */
import moment from "moment";
import { useEffect, useState } from "react"
import { Button, Label, Table, Icon } from "semantic-ui-react"
import { sysAPI } from "../apis/SysAPI";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { AddAdminModal } from "../components/modal/AddAdminModal"
import { TabContainer } from "../components/shared/TabContainer"
import { useHistory } from 'react-router-dom';
import UpdateAdmin from "../components/admin-view/Update";
import { useSelector } from "react-redux";
import AdminCreate from "../components/admin-view/Create";

export const AdminView = () => {
    const me = useSelector((state) => state.me);
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [sysRoles, setSysRoles] = useState([])
    const [addAdminModalState, setAddAdminModalState] = useState(false);

    const getData = () => {
        sysAPI.get('/users').then(response => {
            setUsers(response.data.users);
        });
    }

    useEffect(() => {
        getData()

        sysAPI.get('/sys-roles').then(response => {
            setSysRoles(response.data.data);
        });
    }, []);

    const scopeColors = {
        admin: 'blue',
        full_access: 'grey',
        estate_manual_input: 'yellow',
        advisors: 'green',
        sales: 'purple',
        manual_input: 'orange',
        metrics: 'teal',
        resources: 'pink',
        insurance_manual_input: 'brown'
    };

    const statusColors = {
        active: 'green',
        blocked: 'red',
    };

    const scopeLabels = {
        admin: 'Administrator',
        full_access: 'Full access',
        advisors: 'Advisors',
        sales: 'Sales',
        manual_input: 'Manual input',
        estate_manual_input: 'Estate Manual input',
        metrics: 'Usage Metrics',
        resources: 'Resources',
        insurance_manual_input: 'Insurance'
    };

    /* const handleSyncUp = async () => {
        try {
            const {data} = await sysAPI.post(`/users-sync-up-roles`)
            alert("The Sync Up was finished with success");
            getData();
        } catch (error) {
            console.log({error})
        }
    } */

    const HeaderOptions = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <AdminCreate refreshFunction={getData} sysRoles={sysRoles} />
                {me?.permissions?.includes("roles:rol_view") &&
                    <Button onClick={() => history.push('/dashboard/admin-roles')}>
                        Roles
                    </Button>
                }
                {/* <Button onClick={() => handleSyncUp()}>
                    Sync Up Roles from Scope
                </Button> */}
            </div>
        )
    }

    return (
        <DashboardTemplate scope={'admin'} permission="admin:admin_view">
            <TabContainer
                title='Admin Management'
                subtitle='CRUD of Admins'
                headerOptions={<HeaderOptions />}
            >
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>User Full Name</Table.HeaderCell>
                            <Table.HeaderCell>E-mail</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Last Login</Table.HeaderCell>
                            {/* <Table.HeaderCell>Scope</Table.HeaderCell> */}
                            <Table.HeaderCell>Roles</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users?.map(user => {
                            const status = user.isBlocked ? 'blocked' : user.status;
                            return <Table.Row>
                                <Table.Cell>{user.firstName} {user.lastName}</Table.Cell>
                                <Table.Cell>{user.email}</Table.Cell>
                                <Table.Cell><Label color={statusColors[status]}>{status}</Label></Table.Cell>
                                <Table.Cell>{user.session?.lastLogin && moment(user.session?.lastLogin).format('MM/DD/YYYY HH:mm:ss') || 'N/A'}</Table.Cell>
                                {/* <Table.Cell>{user.scope.split(' ').map(item => <Label color={scopeColors[item]}>{scopeLabels[item]}</Label>)}</Table.Cell> */}
                                <Table.Cell>
                                    {user.roles?.map((rol,index) => (
                                        <Label key={index} color={rol.color || scopeColors[rol.name?.toLowerCase()]}>{rol.name}</Label>
                                    ))}
                                </Table.Cell>
                                <Table.Cell>
                                    <div>
                                        <div>
                                            <UpdateAdmin refreshFunction={getData} dataInfo={user} sysRoles={sysRoles} />
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
            </TabContainer>
            {/* <AddAdminModal
                modalState={addAdminModalState}
                setModalState={value => setAddAdminModalState(value)}
                sysRoles={sysRoles}
            /> */}
        </DashboardTemplate>
    )
}
