import { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import FormRole from "./Form";
import { sysAPI } from "../../apis/SysAPI";
import { useSelector } from "react-redux";

const CreateRole = ({ refreshFunction, defaultRoles }) => {
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [infoRole, setInfoRole] = useState({});

  const handlerCreate = async () => {
    try {
      const { data } = await sysAPI.post(`/sys-roles`, {
        ...infoRole,
      });
      refreshFunction();
      setOpenModal(false);
    } catch (error) {
      console.log({ error });
      alert(error.message);
    }
  };

  if (!me.permissions.includes("roles:rol_add")) {
    return <div></div>;
  }

  return (
    <div>
      <Button icon onClick={() => setOpenModal(true)}>
        Add Role
        <Icon name="add" />
      </Button>

      <Modal size="tiny" open={openModal} onClose={() => setOpenModal(false)} onOpen={() => setOpenModal(true)}>
        <Modal.Header>Create a new role</Modal.Header>
        <Modal.Content>
          <div>
            <FormRole infoRole={infoRole} setInfoRole={setInfoRole} defaultRoles={defaultRoles} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handlerCreate} color="green">
            Create
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
export default CreateRole;
