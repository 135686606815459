import { useSelector } from "react-redux";
import ContinueReviewModal from "./ContinueReviewModal";
import { useState } from "react";
import { sysAPI } from "../../../apis/SysAPI";
import { Button, Icon, Label, Table } from "semantic-ui-react";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const TableRow = ({stage, showButtons, index, request, setclientId, setManualInput, setIndex, setShowContinueReviewModal}) => {
    const me = useSelector(state => state.me);
    const [loader, setLoader] = useState(false)

    const getLabel = (request) => {
        switch (request.status) {
            case 'Pending': return <Label color='red'>PENDING</Label>;
            case 'In review': return <Label color='blue'>IN REVIEW</Label>;
            case 'Peer review': return <Label color='orange'>PEER REVIEW</Label>;
            case 'On Hold': return <Label color='purple'>ON HOLD</Label>;
            case 'Ready': return <Label color='green'>READY</Label>;
            default: break;
        }
    }

    const reviewEstateBtn_Click = async (manualInput, index) => {
        setLoader(true)
        const clientId = manualInput.client._id

        if([4].includes(manualInput.version)){
            for(const index__ of [1,2]){
                try {
                    const {data} = await sysAPI.post(`/estate-manual-input/review`, {
                        clientId,
                        index: index__
                    })
                } catch (error) {
                    console.log("Error in version 4, posible not found, details: ")
                    console.log({error})
                }
            }

            window.location.replace(`/dashboard/estate-manual-input/${clientId}`)
            setLoader(false)
            return
        }

        try {
            const {data} = await sysAPI.post(`/estate-manual-input/review`, {
                clientId,
                index
            })
            window.location.replace(`/dashboard/estate-manual-input/${clientId}/${index}`)
            console.log({data});
        } catch (error) {
            console.log({error})
        }

        setLoader(false)
    }

    const verifyVersionRedirect = (manualInput) => {
        if([4].includes(manualInput.version)) {
            return `/dashboard/estate-manual-input/${manualInput.client._id}`
        }
        return `/dashboard/estate-manual-input/${manualInput.client._id}/${manualInput.index}`
    }

    const handleContinue = async (manualInput_, client, ind) => {
        setManualInput(manualInput_);
        setclientId(client);
        setIndex(ind);
        setShowContinueReviewModal(true);
    }
    
    return <>
        <Table.Row>
            <Table.Cell>{request.client.displayName}</Table.Cell>
            <Table.Cell>
                {request.advisor.fullName}/
                {request.advisor.isOnFreeTrialPeriod ? 'Trial' : request.advisor.hasSubscription  ? 'Purchased' : 'Expired'}
            </Table.Cell>
            <Table.Cell>{request.advisor.nameCompany || request.advisor.companyName}</Table.Cell>
            <Table.Cell>{request.advisor.poc}</Table.Cell>
            <Table.Cell>{getLabel(request)}</Table.Cell>
            <Table.Cell>{moment(request.date).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
            {
                stage === 'Ready' &&
                <Table.Cell>{moment(request.finishReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
            }
            {/*{
                stage === 'In review' &&
                <Table.Cell>{moment(request.startReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
            }
            {
                stage === 'Peer review' &&
                <Table.Cell>{moment(request.finishPeerReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
            } */}

            <Table.Cell>{request.etc}</Table.Cell>

            {(stage === 'In review' || stage === 'Peer review') &&
                <Table.Cell>{request.timeSinceRequest}</Table.Cell>}

            <Table.Cell>{request.reviewer?.firstName ? `${request.reviewer.firstName} ${request.reviewer.lastName}` : 'N/A'}{request.peerReviewer?.firstName ? ` / ${request.peerReviewer.firstName} ${request.peerReviewer.lastName}` : ''}</Table.Cell>
            {me.tags?.includes('reviewersGroup:*') &&
                <Table.Cell>{request.reviewersGroup.join(', ')}</Table.Cell>
            }
            <Table.Cell>
                {request?.status === 'Pending' && request.client._id && showButtons &&
                    // <Link to={`/dashboard/estate-manual-input/${request.client._id}/${request.index}`}>
                        <Button loading={loader} color='green' onClick={() => reviewEstateBtn_Click(request, request.index)}>
                            Start Reviewing
                        </Button>
                    // </Link>
                }
                {request.status === 'In review' && (request.reviewer._id === me._id ) &&
                    <Link to={() => verifyVersionRedirect(request)}>
                        <Button color='blue'>
                            Continue Reviewing
                        </Button>
                    </Link>
                }
                {request.status === 'On Hold' && me.permissions.includes("manual_inputs:button_view_document") &&
                    <Button color='purple' onClick={() => handleContinue(request, request.client._id, request.index)}>
                        Finish Hold
                    </Button>
                }
                {request.status === 'Peer review' && showButtons &&
                    //<Link to={`/dashboard/estate-manual-input/${request.client._id}/${request.index}`}>
                        <Button loading={loader} color='orange' onClick={() => reviewEstateBtn_Click(request, request.index)}>
                            Finish Reviewing
                        </Button>
                    //</Link>
                }
                {(((request.status === 'In review' || request.status === 'Peer review') &&  me.permissions.includes("manual_inputs:button_view_document")) || request.status === 'Ready') &&
                    <Link to={() => verifyVersionRedirect(request)}>
                        <Button>
                            View
                        </Button>
                    </Link>
                }
            </Table.Cell>
        </Table.Row>
    </>
}


const RequestsTable = ({ stage, requests, setSort, setDirection, sort, direction, refreshList }) => {
    const me = useSelector(state => state.me);
    const [showContinueReviewModal, setShowContinueReviewModal] = useState(false);
    const [clientId, setclientId] = useState(undefined);
    const [manualInput, setManualInput] = useState(null);
    const [index, setIndex] = useState(undefined);

    let showButtons = false
    if(me?.tags?.length > 0){
        const existRevierGroup = me.tags.map(tag => tag.split(":")[0]);
        if(existRevierGroup.includes("reviewerGroup")){
            showButtons = true;
        }
    }

    if(me.permissions.includes("manual_inputs:button_start_reviewing")){
        showButtons = true;
    }

    const sortColumn = async (column) => {
        if (column === sort) {
            setDirection(direction === 'ASC' ? 'DESC' : 'ASC');
            return
        }

        setSort(column);
        setDirection('DESC');
    }

    return (<>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Client Name</Table.HeaderCell>
                    <Table.HeaderCell>Requested by</Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'company' ? 'active' : ''}`}
                        onClick={() => sortColumn('company')}
                    >
                        Company
                        {(sort === 'company' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'company' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'company' && <Icon name="sort" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'poc' ? 'active' : ''}`}
                        onClick={() => sortColumn('poc')}
                    >
                        POC
                        {(sort === 'poc' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'poc' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'poc' && <Icon name="sort" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'requestDate' ? 'active' : ''}`}
                        onClick={() => sortColumn('requestDate')}
                    >
                        Request Date
                        {(sort === 'requestDate' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'requestDate' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'requestDate' && <Icon name="sort" />}
                    </Table.HeaderCell>

                    {stage === 'Ready' &&
                    <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'finishDate' ? 'active' : ''}`}
                            onClick={() => sortColumn('finishDate')}
                        >
                            Finish Date
                            {(sort === 'finishDate' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'finishDate' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'finishDate' && <Icon name="sort" />}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'etc' ? 'active' : ''}`}
                            onClick={() => sortColumn('etc')}
                        >
                            ETC
                            {(sort === 'etc' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'etc' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'etc' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'In review' && <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeInReview' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeInReview')}
                        >
                            Time In Review
                            {(sort === 'timeInReview' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeInReview' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeInReview' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'Pending' &&
                    <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeInPending' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeInPending')}
                        >
                            Time In Pending
                            {(sort === 'timeInPending' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeInPending' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeInPending' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'Peer review' &&
                    <>
                        <Table.HeaderCell>Time In Review</Table.HeaderCell>
                    </>}

                    {(stage === 'In review' || stage === 'Peer review') && <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeSinceRequest' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeSinceRequest')}
                        >
                            Time Since Request
                            {(sort === 'timeSinceRequest' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeSinceRequest' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeSinceRequest' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    <Table.HeaderCell>Reviewer</Table.HeaderCell>
                    {me.tags?.includes('reviewersGroup:*') &&
                        <Table.HeaderCell>Assigned to</Table.HeaderCell>
                    }

                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {requests.map((request, index) => (
                    <TableRow 
                        key={index}
                        setclientId={setclientId}
                        setManualInput={setManualInput}
                        setIndex={setIndex}
                        setShowContinueReviewModal={setShowContinueReviewModal}
                        showButtons={showButtons}
                        stage={stage}
                        request={request}
                        index={index}
                    />))}
            </Table.Body>
        </Table>
        {showContinueReviewModal &&
            <ContinueReviewModal
                hideModal={setShowContinueReviewModal}
                showModal={showContinueReviewModal}
                refreshList={refreshList}
                manualInput={manualInput}
                clientId={clientId}
                index={index}
            />
        }
    </>)
}

export default RequestsTable;