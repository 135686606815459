import { useEffect, useState } from "react";
import { Button, Checkbox, Icon, Input, Popup, Select } from "semantic-ui-react";
import * as helpers from "../../helpers/index";
const FormRole = ({ infoRole, setInfoRole, defaultRoles = [] }) => {
  const [name, setName] = useState(infoRole?.name || "");
  const [description, setDescription] = useState(infoRole?.description || "");
  const [permissions, setPermissions] = useState(infoRole?.permissions || []);
  const [key, setKey] = useState(infoRole?.key || []);
  const [color, setColor] = useState(infoRole?.color || []);

  //const defaultRoles = roles;
  const optionsColors = ["", "blue", "orange", "grey", "yellow", "green", "purple", "teal", "pink", "brown", "red", "violet", "black", "olive"];

  const handleUpdatePermissions = (permission) => {
    let newPermissions = JSON.parse(JSON.stringify(permissions));
    if (newPermissions.includes(permission)) {
      newPermissions = newPermissions.filter((per) => per !== permission);
    } else {
      newPermissions.push(permission);
    }
    setPermissions(newPermissions);
  };

  const handleSelectAll = () => {
    let allPermissions = [];
    if (permissions.length <= 0) {
      for (const defRol of defaultRoles) {
        allPermissions = [...allPermissions, ...defRol.permissions.map((per) => per.key)];
      }
    }
    setPermissions(allPermissions);
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
    setKey(helpers.string_to_slug({ str: e.target.value, whitespaces: "_" }));
  };

  useEffect(() => {
    setInfoRole({
      name,
      description,
      permissions,
      color,
      key,
    });

    //eslint-disable-next-line
  }, [name, description, permissions, color, key]);

  return (
    <div>
      <div>
        <Input fluid value={name} onChange={handleChangeName} placeholder="Name" />
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Input fluid value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Input fluid value={key} onChange={(e) => setKey(e.target.value)} placeholder="Key example: this_is_a_key" />
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Select
          fluid
          value={color}
          options={optionsColors.map((color) => {
            return { key: color, value: color, text: color || "Select a color" };
          })}
          onChange={(e, elem) => setColor(elem.value)}
        />
        {/* <Input
          fluid
          value={color}
          onChange={(e) => setColor(e.target.value)}
          placeholder="color"
        /> */}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Permissions</h2>
          <div>
            <Button onClick={() => handleSelectAll()}>{permissions.length > 0 ? "Unselect" : "Select"} All</Button>
          </div>
        </div>
        <div
          style={{
            maxHeight: "450px",
            overflowY: "auto",
            //border: "1px solid #ccc",
            padding: "2px",
          }}
        >
          {defaultRoles.map((role, index) => (
            <div key={index}>
              <h3 style={{ paddingTop: "10px" }}>
                {role.name}
              </h3>
              <div style={{display:"grid", gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))", gap: "10px" }}>
                {role.permissions.map((per, index_2) => (
                  <div key={index_2}>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div >
                        <Checkbox label={per.name} onClick={() => handleUpdatePermissions(per.key)} checked={permissions.includes(per.key)} style={{fontSize: "16px"}} />
                      </div>
                      {per.description && (
                        <div>
                          <Popup content={per.description} trigger={<Icon name="help" />} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {index + 1 < defaultRoles.length && <hr />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormRole;
