import { Button, Dimmer, Icon, Loader, Segment, Table } from "semantic-ui-react";
import { TabContainer } from "../../components/shared/TabContainer";
import { DashboardTemplate } from "../../templates/DashboardTemplate";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CreateSaml from "../../components/saml-view/Create";
import { sysAPI } from "../../apis/SysAPI";
import { useHistory } from "react-router-dom";
import moment from "moment";
import RemoveSaml from "../../components/saml-view/Remove";
import UpdateSaml from "../../components/saml-view/Update";
import * as helper from "../../helpers"

const SamlView = () => {
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await sysAPI.get(`/configs-saml`);
      setItems(data.data);
    } catch (error) {
      console.log({ error });
      alert(error.message);
    }
    setLoading(false);
  };
  const getCompanies = async () => {
    try {
      const { data } = await sysAPI.get(`/companies-v2`, {
        params: {
          allCompanies: true,
        },
      });
      setCompanies(data.data);
    } catch (error) {
      console.log({ error });
    }
  };

  const HeaderOptions = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <CreateSaml refreshFunction={getData} companies={companies} />
      </div>
    );
  };

  useEffect(() => {
    getData();
    getCompanies();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DashboardTemplate scope="saml" permission="saml:saml_view">
        <TabContainer title="Integrations SAML" subtitle="" icon="building" headerOptions={<HeaderOptions />}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Entity</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>URl To Login</Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading && (
                <Table.Row>
                  <Table.Cell colspan={5}>
                    <Segment style={{ height: "300px" }}>
                      <Dimmer active inverted>
                        <Loader>Loading</Loader>
                      </Dimmer>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
              {!isLoading &&
                items.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>{item.entity}</Table.Cell>
                    <Table.Cell>
                      <span
                        className="clickleable"
                        onClick={() => {
                          history.push(`/dashboard/companies/${item.company?._id}`);
                        }}
                      >
                        {item.company?.name}
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="clickleable" onClick={() => helper.copyToClipboard(item.urlToLogin)}>
                        {item.urlToLogin}
                      </div>
                      <div>
                        <a href={item.urlToLogin} target="_blank" rel="noopener noreferrer">
                          <Button size="mini" color="grey">Open Link</Button>
                        </a>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{moment(item.createdAt).format("MM/DD/YYYY HH:mm:ss")}</Table.Cell>
                    <Table.Cell>
                    <div style={{display: "flex", gap: "5px"}}>
                    <div>
                      <UpdateSaml refreshFunction={getData} dataInfo={item} companies={companies} />
                    </div>
                    <div>
                      <RemoveSaml refreshFunction={getData} dataInfo={item} />
                    </div>
                  </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </TabContainer>
      </DashboardTemplate>
    </>
  );
};

export default SamlView;
