import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Message } from "semantic-ui-react";
import { authAPI } from "../../apis/AuthAPI";
import { useState } from "react";
import { FamilyMembersModal } from '../../components/modal/FamilyMembersModal';
import { AssetsModal } from '../../components/modal/AssetsModal';

export const HeaderOptions = ({
    estateManualInputRequest,
    allowSend,
    clientId,
    assetIndex,
    saveForLaterBtn_Click,
    finishReviewBtn_Click,
    loaderSaveForLater,
    loaderFinishReview
}) => {
    const me = useSelector(state => state.me);
    const [loaderPreview, setLoaderPreview] = useState(false)
    const [showFamilyModal, setShowFamilyModal] = useState(false);
    const [showEntitiesModal, setShowEntitiesModal] = useState(false);
    const [showAssetsModal, setShowAssetsModal] = useState(false);

    const openPreview = async () => {
        setLoaderPreview(true)
        try {
            const {data} = await authAPI.post('/single-token', {
                clientId,
                reviewer: estateManualInputRequest.reviewer,
                advisor: estateManualInputRequest.advisor,
                index: assetIndex
            })
            window.open(`${process.env.REACT_APP_FPALPHA_URL}/external-snapshot-preview?token=${data}&area=estate`,'_blank')
        } catch (error) {
            console.log({error})
        }
        setLoaderPreview(false)
    }

    return (<div>
        {((estateManualInputRequest?.status === 'In review' && estateManualInputRequest?.reviewer?._id === me?._id) || estateManualInputRequest?.status === 'Peer review') &&
            <div style={{textAlign: 'right', marginBottom: '1rem'}}>
                <Button color='grey' onClick={saveForLaterBtn_Click} loading={loaderSaveForLater}>Save for later</Button>
                <Button style={{ paddingLeft: '50px', paddingRight: '50px' }} disabled={!allowSend} color='green' onClick={finishReviewBtn_Click} loading={loaderFinishReview}>Finish review</Button>
            </div>
        }
        <div style={{textAlign: 'right', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-end', rowGap: '0.5rem'}}>
            <Button color='red' onClick={() => openPreview()} loading={loaderPreview}>
                Preview
            </Button>
            <Button color='orange' onClick={() => setShowFamilyModal(true)}>
                Add Persons
            </Button>
            <Button color='purple' onClick={() => setShowEntitiesModal(true)}>
                Add Entities
            </Button>
            <Button color='green' onClick={() => setShowAssetsModal(true)}>
                Assets
            </Button>
            {/* estateManualInputRequest?.status === 'In review' && estateManualInputRequest.reviewer._id === me._id &&
                <Button color='grey' onClick={cloneSpouseDataBtn_Click}>
                    Clone data from spouse
                </Button>
             */}
            {(estateManualInputRequest?.status !== 'Ready' || (estateManualInputRequest?.status === 'Ready' && me.permissions.includes('manual_inputs:open_documents'))) &&
                <Button color='blue' onClick={() => window.open(`/dashboard/estate-manual-input/${clientId}/${assetIndex}/files`)}>
                    Open documents for {estateManualInputRequest?.client?.displayName}
                </Button>
            }
        </div>
        <FamilyMembersModal open={showFamilyModal} closeBtn_Click={() => setShowFamilyModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
        <FamilyMembersModal type={'trusts'} open={showEntitiesModal} closeBtn_Click={() => setShowEntitiesModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
        <AssetsModal open={showAssetsModal} closeBtn_Click={() => setShowAssetsModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
    </div>)
}

export const FooterOptions = ({estateManualInputRequest, allowSend, saveForLaterBtn_Click, finishReviewBtn_Click, loaderSaveForLater, loaderFinishReview}) => {
    const me = useSelector(state => state.me);

    return <>
        {((estateManualInputRequest?.status === 'In review' && estateManualInputRequest?.reviewer?._id === me?._id) || estateManualInputRequest?.status === 'Peer review') &&
            <Container fluid textAlign='right' style={{ marginTop: '20px' }}>
                <Button color='grey' onClick={() => saveForLaterBtn_Click()} loading={loaderSaveForLater} >Save for later</Button>
                <Button style={{ paddingLeft: '50px', paddingRight: '50px' }} disabled={!allowSend} color='green' onClick={() => finishReviewBtn_Click()} loading={loaderFinishReview}>Finish review</Button>
            </Container>
        }
        {estateManualInputRequest?.status === 'Ready' &&
            <Message color='green'>This manual input has already been marked as REVIEWED.</Message>
        }
    </>
}