/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { commifyNumber } from '../../../helpers';
import { paymentAPI } from '../../../apis/PaymentAPI';
import { Input, Button, Table, Dropdown, Header } from 'semantic-ui-react';

const CompanyPurchasedCredits = ({ activeMenu }) => {
  const params = useParams();
  const { companyId } = params;
  const companyProfile = useSelector((state) => state.currentCompany);
  const allowedViews = {
    fulltaxPurchased: 'Fulltax',
    estatePurchased: 'Estate Snapshot',
    homePurchased: 'Home Snapshot',
    autoPurchased: 'Auto Snapshot',
  };

  let advisorsOptions = companyProfile?.advisorList?.map(itm => ({key: itm._id, value: itm._id, text: itm.fullName})) || [];
  advisorsOptions = [{key:"", text: "All", value:""},...advisorsOptions]

  // const today = moment();
  // const minDate = moment('2023-01-01');

  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD').toString());
  const [finishDate, setFinishDate] = useState(moment().format('YYYY-MM-DD').toString());
  const [advisor, setAdvisor] = useState('');
  const [loader, setLoader] = useState(false)
  const [dataRow, setDataRow] = useState([])

  const changeDate = (type, val) => {
    if (type === 'start') {
      setStartDate(val)

      /* const finishDate = moment(val).add(30, 'days');
      const finishDateString = finishDate.isSameOrBefore(today)
        ? finishDate.format('YYYY-MM-DD').toString()
        : today.format('YYYY-MM-DD').toString();

      setFinishDate(finishDateString); */
    }

    if (type === 'finish') {
      setFinishDate(val)

      /* const startDate = moment(val).subtract(30, 'days');
      const startDateString = startDate.isSameOrAfter(minDate)
        ? startDate.format('YYYY-MM-DD').toString()
        : minDate.format('YYYY-MM-DD').toString();

      setStartDate(startDateString); */
    }
  };

  const BuildedRow = ({data}) => {
    return(
      <Table.Row>
        {/* <Table.Cell> {data._id} </Table.Cell> */}
        <Table.Cell> {data.title} </Table.Cell>
        <Table.Cell> {commifyNumber(data.creditsQuantity || 0)} </Table.Cell>
        <Table.Cell> ${commifyNumber(data.total)} </Table.Cell>
        {/* <Table.Cell> {data.area} </Table.Cell> */}
        <Table.Cell> {data.advisorName} </Table.Cell>
        {/* <Table.Cell> {data.companyName} </Table.Cell> */}
        <Table.Cell> {data.paymentMethod} </Table.Cell>
        <Table.Cell> {data.stripeId} </Table.Cell>
        <Table.Cell> {moment(data.creationDate).format("YYYY-MM-DD HH:mm:ss")} </Table.Cell>
        {/* <Table.Cell><Button color="red" icon="trash alternate" /></Table.Cell> */}
      </Table.Row>
    );
  };

  const handleFilter = async () => {
    setLoader(true)
    try {
      const area = activeMenu.replace("Purchased", "");
      const {data} = await paymentAPI.get(`/advisor/detail`,{
        params: {
          companyId: companyId,
          advisorId: advisor,
          area,
          periodStart: startDate,
          periodEnd: finishDate,
        }
      })
      //console.log("FILTAR BUSQUEDA")
      //console.log({activeMenu, startDate, finishDate, advisor, data})
      setDataRow(data[area]);

    } catch (error) {
      console.log("Oops... an error was found 👇🏼")
      console.log(error)
      setDataRow([])
    }
    setLoader(false)
  }

  useEffect(() => {
    const area = activeMenu.replace("Purchased", "");
    if(["home", "auto", "estate"].includes(area)){
      handleFilter()
    }
  },[activeMenu, startDate, finishDate, advisor])

  return (<>
    {Object.keys(allowedViews).includes(activeMenu) && <>
      <Header as="h1">{allowedViews[activeMenu]} Purchased</Header>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr auto',
          gap: '1em',
        }}
      >
        <Input
          value={startDate}
          onChange={(_, value) => changeDate('start', value.value)}
          type="date"
          label="From:"
          />
          {/* min={minDate.format('YYYY-MM-DD').toString()}
          max={today.subtract(30, 'days').format('YYYY-MM-DD').toString()} */}
        <Input
          value={finishDate}
          onChange={(_, value) => changeDate('finish', value.value)}
          type="date"
          label="To:"
          />
        {/*  min={minDate.add(30, 'days').format('YYYY-MM-DD').toString()}
          max={today.format('YYYY-MM-DD').toString()} */}
        <Dropdown
        placeholder="Advisor:"
          options={advisorsOptions}
          value={advisor}
          onChange={(_, val) => { setAdvisor(val.value) }}
          fluid
          selection
          search
        />
        <Button onClick={() => handleFilter()} disabled={loader}> {loader ? 'Loading...' : 'Filter'} </Button>
      </div>

      <Table>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell>ID</Table.HeaderCell> */}
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Credits</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
            {/* <Table.HeaderCell>Area</Table.HeaderCell> */}
            <Table.HeaderCell>Advisor Name</Table.HeaderCell>
            {/* <Table.HeaderCell>Company Name</Table.HeaderCell> */}
            <Table.HeaderCell>Card</Table.HeaderCell>
            <Table.HeaderCell>Transaction ID</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>

          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dataRow.map((data, index) => (
            <BuildedRow key={index} data={data} />
          ))}

        </Table.Body>
      </Table>
    </>}
  </>);
}

export default CompanyPurchasedCredits;
