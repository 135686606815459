/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Icon, Input, Segment, Select, Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { addEstateManualInputAsset, removeEstateManualInputAsset, setEstateManualInputField } from '../../actions/estate-manual-input.actions';
import { v4 as uuid } from 'uuid';
import { InputItem, DocInputItem } from '../shared/EstateInputItem';
import { SelectFamilyMember } from '../shared/SelectFamilyMember';
import { useState, useEffect } from 'react';
import { FamilyMembersModal } from '../modal/FamilyMembersModal';
import { usStates } from '../../data/usStates';

const TrustGuardians = ({ docId, version }) => {
    const dispatch = useDispatch();

    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInput = useSelector(state => state.estateManualInput);

    const guardianMinorChildren = estateManualInputAssets?.[`${docId}_guardianMinorChildren`];

    const addGuardianBtn_Click = (beneficiaryType) => {
        return () => {
            const id = uuid();
            dispatch(addEstateManualInputAsset(`${docId}_guardianMinorChildren`, id));
            dispatch(setEstateManualInputField(docId, `guardianType___${id}`, beneficiaryType || 'guardian'));
        }
    };

    const removeGuardianBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                `${docId}_guardianMinorChildren`,
                asset.id,
                ['guardian', 'guardianType'],
                docId,
            ));
        }
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            <h4>Guardian for minor childrens</h4>
            {guardianMinorChildren?.length > 0 &&
                <Segment.Group>
                    {guardianMinorChildren?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <SelectFamilyMember
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        field={`guardian___${asset.id}`}
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeGuardianBtn_Click(asset)}>Remove</Button>
                                </div>
                            </Segment>
                        )
                    })}
                </Segment.Group>
            }
            <Button onClick={addGuardianBtn_Click('guardian')}>Add Guardian</Button>
        </div>
    )
}

const WillCreatesTypeOfTrust = ({ docId, version }) => {
    const estateManualInput = useSelector(state => state.estateManualInput);
    const client = useSelector(state => state.currentClient);

    const [showTrustsModal, setShowTrustsModal] = useState(false);

    if (estateManualInput?.docId?.['createsTypeOfTrust'] !== 'Yes') {
        return <></>;
    }

    const trustOptions = client?.estateContactsList?.filter(c => c.type === 'trust').map(t => ({ key: t._id, value: t._id, text: `${t.firstName} ${t.lastName}` }));

    return (<div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ flex: 1, marginRight: '10px' }}>
            <InputItem
                indent={4}
                docType={docId}
                label='Specify the trust'
                field='typeOfTrust'
                nOptions={trustOptions}
                style={{ marginBottom: '0' }}
                version={version}
            />
        </div>
        <Button onClick={() => setShowTrustsModal(true)}>Create trust</Button>
        <FamilyMembersModal open={showTrustsModal} closeBtn_Click={() => setShowTrustsModal(false)} type='trusts' />
    </div>)
}

const WillAssets = ({ docId, version }) => {
    const dispatch = useDispatch();

    const client = useSelector(state => state.currentClient);
    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const estateManualInputChange = useSelector(state => state.estateManualInputChange);

    const willAssets = estateManualInputAssets?.[docId];
    const self = client?.estateContactsList?.find(c => c.relation === 'Self');

    const addAssetBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputAsset(docId, id));
    }

    const removeAssetBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                docId,
                asset.id,
                ['assetType', 'primaryBeneficiaries', 'contingentBeneficiaries', 'whenWillTransfer'],
            ));
        }
    };

    const findRelation = (relativeId, relation) => {
        if (relation === 'Self') {
            return 'Self';
        }

        let contact = client?.estateContactsList?.find(c => c._id === relativeId);
        if (!contact) {
            contact = self;
        }

        return `${contact.firstName}'s ${relation}`;
    };

    const addBeneficiaryBtn_Click = (beneficiaryType, internalAssetId) => {
        return () => {
            const datapoint = `${beneficiaryType}Beneficiaries___${internalAssetId}`;
            const beneficiaryId = estateManualInput?.[docId]?.[datapoint];

            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
            const contactsIds = beneficiariesList.map(b => b.contactId);

            if (!contactsIds.includes(beneficiaryId)) {
                beneficiariesList.push({ contactId: beneficiaryId, amountType: '%', amount: '0' });
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }

            dispatch(setEstateManualInputField(docId, `${beneficiaryType}Beneficiaries___${internalAssetId}`, ''));
        }
    }

    const removeBeneficiaryBtn_Click = (beneficiaryType, internalAssetId, contactId) => {
        return () => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const index = beneficiariesList.indexOf(contactId);
            beneficiariesList.splice(index, 1);
            dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
        }
    }

    const beneficiaryAmount_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.amount = elem.value;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const beneficiaryAmountType_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.amountType = elem.value;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const beneficiaryPerStirpes_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.perStirpes = elem.checked;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const BeneficiariesList = (beneficiaryType, internalAssetId) => {
        let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
        beneficiariesList = JSON.parse(beneficiariesList);
        beneficiariesList = beneficiariesList?.map(beneficiary => ({ ...beneficiary, contact: client?.estateContactsList?.find(c => c._id === beneficiary.contactId) }));

        return (<div style={{ display: beneficiariesList?.length > 0 ? 'block' : 'none', margin: '10px 0 10px 270px' }}>
            {beneficiariesList.map(beneficiary => {
                return <div style={{
                    padding: '10px', borderBottom: '1px solid #e8e8e8', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                    border: estateManualInputChange && docId in estateManualInputChange && `${beneficiaryType}BeneficiariesList___${internalAssetId}` in estateManualInputChange[docId] ? '1px solid rgba(21, 97, 198, 0.5)' : 'none',
                    borderRadius: '0.28571429rem',
                    boxShadow: estateManualInputChange && docId in estateManualInputChange && `${beneficiaryType}BeneficiariesList___${internalAssetId}` in estateManualInputChange[docId] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none'
                }}>
                    <div>{beneficiary.contact?.firstName} {beneficiary.contact?.lastName} [{findRelation(beneficiary.contact?.relatedTo, beneficiary.contact?.relation)}]</div>
                    <div>
                        {estateManualInputRequest.estateSnapshotVersion === 2 &&
                            <div style={{ width: '100px', display: 'inline-block' }}>
                                <Checkbox
                                    checked={beneficiary.perStirpes}
                                    label='per stirpes'
                                    value={beneficiary.perStirpes}
                                    onChange={beneficiaryPerStirpes_Change(beneficiary, beneficiaryType, internalAssetId)}
                                />
                            </div>
                        }
                        <div style={{ width: '50px', display: 'inline-block' }}>
                            <Select fluid
                                options={[{ value: ' ', text: ' ' },{ value: '%', text: '%' }, { value: '$', text: '$' },]}
                                defaultValue={beneficiary.amount !== "0" ? beneficiary.amountType : ''}
                                onChange={beneficiaryAmountType_Change(beneficiary, beneficiaryType, internalAssetId)}
                            />
                        </div>
                        <Input
                            style={{ width: '120px', margin: '0 30px 0 5px' }}
                            value={beneficiary.amount}
                            onChange={beneficiaryAmount_Change(beneficiary, beneficiaryType, internalAssetId)}
                        />
                        <Icon name='delete' inverted color='black' size='large' onClick={removeBeneficiaryBtn_Click(beneficiaryType, internalAssetId, beneficiary.contact?._id)} />
                    </div>
                </div>
            })}
        </div>);
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            {willAssets?.length > 0 &&
                <div>
                    {willAssets?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start', marginBottom: '10px' }}>
                                    <InputItem
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        nOptions={client?.estateAssetsList?.map(asset => ({ key: asset._id, value: asset._id, text: asset.name }))}
                                        field={`assetId___${asset.id}`}
                                        ignoreBorder
                                        version={version}
                                        withoutPages
                                    />
                                    <Button color='red' onClick={removeAssetBtn_Click(asset)}>Remove</Button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <SelectFamilyMember docType={docId} label='Primary beneficiaries' style={{ marginBottom: '0px', marginRight: '10px' }} field={`primaryBeneficiaries___${asset.id}`} version={version} message={'Different Answer'} />
                                    <Button onClick={addBeneficiaryBtn_Click('primary', asset.id)}>Add</Button>
                                </div>
                                {BeneficiariesList('primary', asset.id)}
                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`} version={version} />

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '10px' }}>
                                    <SelectFamilyMember docType={docId} label='Contingent beneficiaries' style={{ marginBottom: '0px', marginRight: '10px' }} field={`contingentBeneficiaries___${asset.id}`} version={version} withoutPages />
                                    <Button onClick={addBeneficiaryBtn_Click('contingent', asset.id)}>Add</Button>
                                </div>
                                {BeneficiariesList('contingent', asset.id)}
                                <InputItem docType={docId} label='When will it transfer to contingent beneficiaries?' field={`whenWillTransferContingentBeneficiary___${asset.id}`} version={version} withoutPages />

                                {estateManualInputRequest.estateSnapshotVersion !== 2 &&
                                    <InputItem docType={docId} multiline label='Additional information' field={`additionalInfo___${asset.id}`} ignoreBorder version={version} />
                                }
                            </Segment>
                        )
                    })}
                </div>
            }
            <Button style={{ marginTop: '10px' }} onClick={addAssetBtn_Click}>Add Asset</Button>
        </div>
    )
}

const WillExectutors = ({ docId, version }) => {
    const dispatch = useDispatch();

    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const client = useSelector(state => state.currentClient);

    const willExecutors = estateManualInputAssets?.[`${docId}_executors`];

    const addExecutorBtn_Click = (executorType) => {
        return () => {
            const id = uuid();
            dispatch(addEstateManualInputAsset(`${docId}_executors`, id));
            dispatch(setEstateManualInputField(docId, `executorType___${id}`, executorType || 'executor'));
        }
    };

    const removeExecutorBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                `${docId}_executors`,
                asset.id,
                ['executor', 'executorType'],
                docId,
            ));
        }
    };

    const executorTypes = {
        'executor': 'Main',
        'successive_executor': 'Successor',
        'alternate_executor': 'Alternate',
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            <h4>Executors</h4>
            {willExecutors?.length > 0 &&
                <Segment.Group>
                    {willExecutors?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    {/* <h4>Executor #{asset.id.substring(0, 8)}</h4> */}
                                    <h4 style={{ margin: '10px 10px 0 0' }}>{executorTypes[estateManualInput?.[docId]?.[`executorType___${asset.id}`]]}</h4>
                                    <InputItem
                                        docType={docId}
                                        // label='Name'
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        nOptions={client?.estateContactsList?.map(c => ({ key: c._id, value: c._id, text: `${c.firstName} ${c.lastName}` })) || []}
                                        field={`executor___${asset.id}`}
                                        ignoreBorder
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeExecutorBtn_Click(asset)}>Remove</Button>
                                </div>
                            </Segment>
                        )
                    })}
                </Segment.Group>
            }
            <Button onClick={addExecutorBtn_Click('executor')}>Add Executor</Button>
            <Button onClick={addExecutorBtn_Click('successive_executor')}>Add Successor Executor</Button>
            {/* <Button onClick={addExecutorBtn_Click('alternate_executor')}>Add Alternate Executor</Button> */}
        </div>
    )
}

const WillAssetsContainer = ({ docId, version }) => {
    const estateManualInput = useSelector(state => state.estateManualInput);

    if (estateManualInput?.[docId]?.willListAssets !== 'Yes') {
        return <></>;
    }

    return <WillAssets docId={docId} version={version} />;
}

const AgentsBlock = ({ docId, parentField, version }) => {
    const estateManualInput = useSelector(state => state.estateManualInput);

    if (estateManualInput?.[docId]?.[parentField] !== 'Yes') {
        return <></>;
    }

    const statesOptions = [
        { key: '', value: '', text: '' },
        ...Object.keys(usStates).map(state => ({
            key: usStates[state],
            value: state,
            text: usStates[state],
        })),
    ];

    return (
        <div>
            {parentField === 'healthCarePOAs' &&
                <>
                    <InputItem docType={docId} indent={4} label='Date' field={`${parentField}_dateOfHealth`} version={version} withoutPages dateFormat />
                    {/* <InputItem docType={docId} indent={4} label='Health care POA Grantor' field={`${parentField}_grantor`}version={version} /> */}
                    <InputItem docType={docId} indent={4} label='Health care POA Agent' field={`${parentField}_agent`} version={version} />
                    <InputItem docType={docId} indent={4} label='Health care POA Succesive Agents' field={`${parentField}_succesiveAgents`} version={version} />
                    <InputItem docType={docId} indent={4} label={`Is there a HIPAA Paragraph?`} field={`${parentField}_hipaaParagraph`} options={['Yes', 'No']} version={version} />
                    <InputItem
                        docType={docId}
                        indent={4}
                        label={`State`}
                        field={`${parentField}_state`}
                        nOptions={statesOptions}
                        version={version}
                    />
                </>}
            {parentField !== 'healthCarePOAs' &&
                <>
                    <InputItem docType={docId} indent={4} label='Date of POA' field={`${parentField}_dateOfPOA`} version={version} withoutPages dateFormat />
                    <InputItem docType={docId} indent={4} label={`Agent's name`} field={`${parentField}_agentName`} version={version} />
                    <InputItem docType={docId} indent={4} label={`Successive agents`} field={`${parentField}_successiveAgents`} version={version} />
                    {parentField === 'powerOfAttorney' &&
                        <>
                            <InputItem docType={docId} indent={4} label={`Is it springing?`} field={`${parentField}_springing`} options={['Yes', 'No']} version={version} />
                            <InputItem docType={docId} indent={4} label={`Are there elder abuse provisions?`} field={`${parentField}_elderAbuseProvisions`} options={['Yes', 'No']} version={version} />
                            <InputItem docType={docId} indent={4} label={`Can the agent make gift and other tax-related decisions?`} field={`${parentField}_taxRelatedDecisions`} options={['Yes', 'No']} version={version} />
                            <InputItem
                                docType={docId}
                                indent={4}
                                label={`State`}
                                field={`${parentField}_state`}
                                nOptions={statesOptions}
                                version={version}
                            />
                        </>}
                </>}
            <InputItem docType={docId} indent={4} label={`Add material information`} field={`${parentField}_materialInfo`} multiline version={version} withoutPages />
        </div>
    )
}

const OtherInformationBlocks = ({ docId, version }) => {
    const dispatch = useDispatch();

    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);

    const additionalInfoBlocks = estateManualInputAssets?.[`${docId}_additionalInfo`];

    const addBlockBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputAsset(`${docId}_additionalInfo`, id));
    }

    const removeBlockBtn_Click = (blockId) => {
        return () => {
            dispatch(removeEstateManualInputAsset(`${docId}_additionalInfo`, blockId, ['content']));
        }
    }

    if (!version?.includes(estateManualInputRequest.estateSnapshotVersion)) {
        return <></>;
    }

    return (
        <div>
            <p>What other material information is contained in the document?</p>
            {additionalInfoBlocks?.map((block, i) => {
                return <InputItem
                    version={version}
                    key={block.id}
                    multiline={true}
                    docType={docId}
                    field={`content___${block.id}`}
                    label={`#${i + 1}`}
                    rightComponent={() => {
                        return <Button color='red' style={{ marginLeft: '10px' }} onClick={removeBlockBtn_Click(block.id)}>
                            Remove
                        </Button>
                    }}
                />
            })}
            <Button onClick={addBlockBtn_Click}>Add paragraph</Button>
        </div>
    )
}

export const PoaForm = ({ docId }) => {
    const dispatch = useDispatch();
    const client = useSelector(state => state.currentClient);
    // const estateManualInput = useSelector(state => state.estateManualInput);
    // const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    // const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    // const estateManualInputChange = useSelector(state => state.estateManualInputChange);

    const trustNameOptions = client?.estateContactsList
        ?.filter(c => c.relation === 'Trust')
        ?.map(c => ({ key: c._id, value: c._id, text: `${c.firstName}` }))
        || [];

    useEffect(() => {
        dispatch(setEstateManualInputField(docId, 'powerOfAttorney', 'Yes'));
    }, []);

    // console.log('POA:', estateManualInput);
    // console.log('Assets:', estateManualInputAssets);
    // console.log('Request:', estateManualInputRequest);

    return (
        <>
            <InputItem checkbox label="Incomplete documents" withoutPages docType={docId} field='isDocumentIncomplete' version={[1,2,3,4]}/>
            <InputItem docType={docId} label='Power of attorney' field='powerOfAttorney' options={['Yes', 'No']} version={[1,2,3,4]} withoutPages />
            <AgentsBlock docId={docId} parentField='powerOfAttorney' version={[1,2,3,4]} />
        </>
    )
}