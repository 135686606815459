/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { sysAPI } from "../apis/SysAPI";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { Button, Icon, Pagination, Container, Table, Input, Dropdown, Loader, Segment, Dimmer } from 'semantic-ui-react'
import { TabContainer } from '../components/shared/TabContainer';
import { useSelector } from "react-redux";
import MessageTablesEmpty from "../components/globals/MessageTablesEmpty";

const DashboardAdvisorsSnapshotsView = () => {
    const history = useHistory();
    const me = useSelector(state => state.me);

    const pageSize = 15;
    //const [pageSize, setPageSize] = useState(15)
    const [advisorsList, setAdvisorsList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalAdvisors, setTotalAdvisors] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    // const [company, setCompany] = useState('');
    const [includeCompanies, setIncludeCompanies] = useState([]);
    const [excludeCompanies, setExcludeCompanies] = useState([]);
    const [license, setlicense] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [businessCategoriesOptions, setBusinessCategoriesOptions] = useState([]);
    const [businessCategory, setBusinessCategory] = useState('');
    // const [search, setSearch] = useState({activePage: 1, company: '', searchInput: '', license: ''});
    const [search, setSearch] = useState({activePage: 1, includeCompanies: '', excludeCompanies: '', searchInput: '', license: ''});

    const [sort, setSort] = useState('');
    const [sortType, setSortType] = useState('ASC');
    const [sortChange, setSortChange] = useState(0);
    const [download, setDownload] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [counterSearched, setCounterSearched] = useState(-1)

    const licenseList = [
        {text: 'All', value: 'All', key: 0},
        {text: 'Trial', value: 'Trial', key: 1},
        {text: 'Licensed/Active', value: 'Purchased', key: 2},
        {text: 'Other', value: 'Other', key: 3},
        // {text: 'Purchased license', value: 'purchasedLicense', key: 1},
        // {text: 'On Free Trial', value: 'onFreeTrial', key: 2},
        // {text: 'With Current Free Trial', value: 'currentFreeTrial', key: 3},
        // {text: 'With Expired Free Trial', value: 'expiredFreeTrial', key: 4}
    ];

    const apiCall = async () => {
        setIsLoading(true)
        //setTotalPages(1)
        //setTotalAdvisors(0)
        try {
            const response = await sysAPI.get(`/advisors`,{
                params: {
                    page: search.activePage,
                    size: pageSize,
                    query: searchInput,
                    include: `${includeCompanies}`,
                    exclude: `${excludeCompanies}`,
                    license: license,
                    sort: sort,
                    sorttype: sortType,
                    businesscategory:businessCategory,
                    withSnapshots: true,
                }
            })

            const { advisorsList, totalPages, total } = response.data;
            setAdvisorsList(advisorsList);
            setTotalPages(totalPages);
            setTotalAdvisors(total);
            setCounterSearched(counterSearched+1)
        } catch (error) {
            console.log(error)
            alert('Oops... Error: ' + error.message)
        }
        setDownload(false);
        setIsLoading(false);
    }

    const exportCall = async () => {
        setDownload(true);
        try {
            const {data} = await sysAPI.get(`/advisors/export`, {
                params: {
                    query: searchInput,
                    include: `${includeCompanies}`,
                    exclude: `${excludeCompanies}`,
                    license: license,
                    sort: sort,
                    sorttype: sortType,
                    businesscategory:businessCategory,
                    withSnapshots: true,
                },
                responseType: "blob"
            })
            //console.log({data})
            const href = URL.createObjectURL(data);
            const link = document.createElement("a");

            link.href = href;
            link.setAttribute('download', `Advisor Snapshots List ${moment().format('MM-DD-YYYY hh.mm.ss')}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            //URL.removeObjectURL(href);
            setDownload(false);
        } catch (error) {
            console.log(error);
            alert('Oops... Error: ' + error.message)
            setDownload(false);
        }
    }

    useEffect(() => {
        setBusinessCategoriesOptions([
            {
                value: '',
                text: '',
            },
            {
                value: 'implementation',
                text: 'Implementation',
            },
            {
                value: 'adoption',
                text: 'Adoption',
            },
            {
                value: 'growth',
                text: 'Growth',
            },
            {
                value: 'maintenance',
                text: 'Maintenance',
            },
        ]);

        sysAPI.get(`/companies`).then(response => {
            const { companiesList } = response.data;
            const list = companiesList.map((company, ind) => ({ key: ind, text: company, value: company }))
            list.unshift({ key: 'all', text: 'All', value: 'All' })
            setCompaniesList(list);
        }).catch(err => {
            console.error(err);
        });
        setCounterSearched(counterSearched+1)
        //apiCall();
    }, []);

    useEffect(() => {
        if(counterSearched > -1 && !download){
            apiCall()
        }
        if(download){
            exportCall()
        }
    }, [search, sortChange]);

    const pageChange = (e, { activePage }) => {
        setSearch({...search, activePage});
    }

    const searchBtn_Click = () => {
        // setSearch({activePage: 1, company, searchInput, license});
        setSearch({activePage: 1, includeCompanies, excludeCompanies, searchInput, license});
    }

    const exportBtn_Click = () => {
        setDownload(true);
        //setIsLoading(true);
        setSearch({activePage: 1, includeCompanies, excludeCompanies, searchInput, license});
    }
    const advisorRow_Click = (advisor) => {
        return () => {
            history.push(`/dashboard/advisors/${advisor._id}`);
        }
    }

    const sortColumn = (column) => {
        if(isLoading) {
            console.log('Wait please...')
            return
        }

        if (column === sort) {
            if(sortType === 'ASC'){
                setSortType('DESC')
            }
            else if(sortType === 'DESC') {
                setSort('');
                setSortType('')
            }
            else{
                setSortType('ASC')
            }
            setSortChange(sortChange + 1)
            return
        }

        setSort(column);
        setSortType('ASC');
        setSortChange(sortChange + 1)
    }

    const headersCell = [
        {label: "Home Snapshots", sortValue: "homeSnap"},
        {label: "Auto Snapshots", sortValue: "autoSnap"},
        {label: "Estate Snapshots", sortValue: "estateSnap"},
        {label: "Tax Snapshots", sortValue: "fulltaxSnap"},
        {label: "Total Snapshots", sortValue: "totalSnap"},
    ]

    return (
        <DashboardTemplate scope='advisors' permission="snapshots:snapshots_view">
            <TabContainer
                title='Advisors Snapshots'
                subtitle='Count Snapshots from Advisors'
            >
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        gridTemplateRows: 'auto auto',
                        gridTemplateAreas:`
                            "inputs1 button1"
                            "inputs2 button2"`,
                        gap: '12px 12px',
                    }}
                >
                    <div>
                        <Input
                            value={searchInput}
                            onChange={e => setSearchInput(e.currentTarget.value)}
                            icon='search'
                            iconPosition='left'
                            onKeyPress={e => (e.charCode === 13 || e.keyCode === 13 || e.which === 13) && searchBtn_Click()}
                            fluid
                        />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '12px', gridArea: 'inputs2' }}>
                        <Dropdown placeholder='Include Companies' multiple selection search options={companiesList} value={includeCompanies} onChange={(e, { value }) => {setIncludeCompanies(value)}} />
                        <Dropdown placeholder='Exclude Companies' multiple selection search options={companiesList} value={excludeCompanies} onChange={(e, { value }) => {setExcludeCompanies(value)}} />
                        <Dropdown placeholder='License Type' selection search options={licenseList} value={license} onChange={(e, { value }) => {setlicense(value)}} />
                        <Dropdown placeholder='Business Categories' selection options={businessCategoriesOptions} value={businessCategory} onChange={(e, { value }) => {setBusinessCategory(value)}} />
                    </div>
                    <Button style={{ gridArea: 'button1' }} onClick={searchBtn_Click} disabled={isLoading} loading={isLoading}>Filter</Button>
                    {me?.permissions?.includes("snapshots:snapshot_download_query") &&
                        <Button style={{ gridArea: 'button2' }} onClick={exportBtn_Click} disabled={download || isLoading} loading={download}>{download ? 'Loading...' : 'Download'}</Button>
                    }
                </div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Full Name</Table.HeaderCell>
                            <Table.HeaderCell style={{maxWidth: '200px'}}>Email</Table.HeaderCell>
                            <Table.HeaderCell
                                className={`table-header__order ${sort === 'company' ? 'active' : ''}`}
                                onClick={() => sortColumn('company')}
                                style={{minWidth: '115px'}}
                            >
                                Company
                                {(sort === 'company' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'company' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'company' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                className={`table-header__order ${sort === 'poc' ? 'active' : ''}`}
                                onClick={() => sortColumn('poc')}
                            >
                                POC
                                {(sort === 'poc' && sortType === 'ASC') && <Icon name="sort up" />}
                                {(sort === 'poc' && sortType === 'DESC') && <Icon name="sort down" />}
                                {sort !== 'poc' && <Icon name="sort" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Business Category</Table.HeaderCell>
                            {headersCell.map((header) => (
                                <Table.HeaderCell
                                    className={`table-header__order ${sort === `${header.sortValue}` ? 'active' : ''}`}
                                    onClick={() => sortColumn(`${header.sortValue}`)}
                                >
                                    {header.label}
                                    {(sort === `${header.sortValue}` && sortType === 'ASC') && <Icon name="sort up" />}
                                    {(sort === `${header.sortValue}` && sortType === 'DESC') && <Icon name="sort down" />}
                                    {sort !== `${header.sortValue}` && <Icon name="sort" />}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        {isLoading ? (
                            <>
                                <Table.Row>
                                    <Table.Cell colSpan={11}>
                                        <Segment style={{ width: '100%', height: '300px' }}>
                                            <Dimmer active inverted>
                                                <Loader inverted>Loading</Loader>
                                            </Dimmer>
                                        </Segment>
                                    </Table.Cell>
                                </Table.Row>
                            </>
                        ) : (
                            <>
                                {advisorsList?.map(advisor => (
                                    <Table.Row key={advisor._id} style={{ color: advisor.hasActiveSession ? 'green' : 'black' }}>
                                        <Table.Cell>
                                            <span style={{ cursor: 'pointer' }} onClick={advisorRow_Click(advisor)}>{advisor.fullName}</span>
                                        </Table.Cell>
                                        <Table.Cell>{advisor.email}</Table.Cell>
                                        <Table.Cell>
                                            <span style={{ cursor: 'pointer' }} onClick={() => history.push(`/dashboard/companies/${advisor.companyId}`)}>
                                                {advisor.nameCompany}
                                            </span>
                                            </Table.Cell>
                                        <Table.Cell>{advisor.poc}</Table.Cell>
                                        <Table.Cell>{advisor.status}</Table.Cell>
                                        <Table.Cell>{businessCategoriesOptions.find(e => e.value === advisor.businessCategory)?.text}</Table.Cell>
                                        <Table.Cell textAlign="center">{advisor.homeSnapshots || 0}</Table.Cell>
                                        <Table.Cell textAlign="center">{advisor.autoSnapshots || 0}</Table.Cell>
                                        <Table.Cell textAlign="center">{advisor.estateSnapshots || 0}</Table.Cell>
                                        <Table.Cell textAlign="center">{advisor.fulltaxSnapshots || 0}</Table.Cell>
                                        <Table.Cell textAlign="center">{advisor.totalSnapshots || 0}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </>
                        )}
                    </Table.Body>
                </Table>
                {!isLoading && advisorsList.length < 1 &&  <MessageTablesEmpty counterSearched={counterSearched} />}
                <Container
                    fluid
                    textAlign='right'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: '32px',
                    }}
                >
                    <div style={{ fontSize: '1.2em' }}>Total Advisors found: {totalAdvisors}</div>
                    <Pagination totalPages={totalPages} activePage={search.activePage} onPageChange={pageChange} disabled={isLoading} />
                </Container>
            </TabContainer>
        </DashboardTemplate>
    )
}

export default DashboardAdvisorsSnapshotsView