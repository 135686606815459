import { DashboardTemplate } from "../templates/DashboardTemplate";
import { TabContainer } from "../components/shared/TabContainer";
import { Button, Icon, Label, Table } from "semantic-ui-react";
import CreateRole from "../components/roles/Create";
import UpdateRole from "../components/roles/Update";
import { useEffect, useState } from "react";
import { sysAPI } from "../apis/SysAPI";
import RemoveRoles from "../components/roles/Remove";
const RolesView = () => {
  const [roles, setRoles] = useState([]);
  const [defaultRoles, setDefaultRoles] = useState([]);

  const getData = async () => {
    try {
      const {data:roles} = await sysAPI.get(`/sys-roles`);
      setRoles(roles.data);

      const {data: permissions} = await sysAPI.get("/sys-permissions")
      setDefaultRoles(permissions.data)
    } catch (error) {
      console.log({error})
    }
  };

  useEffect(() => {
    getData()
  },[])

  const HeaderOptions = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <CreateRole refreshFunction={getData} defaultRoles={defaultRoles} />
      </div>
    );
  };


  return (
    <DashboardTemplate scope={"admin"} permission="roles:rol_view">
      <TabContainer title="Roles Management" subtitle="CRUD of Roles" headerOptions={<HeaderOptions />}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name Role</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              {/* <Table.HeaderCell>Key</Table.HeaderCell> */}
              <Table.HeaderCell>Total Permissions</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {roles?.map((rol, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Label color={rol.color}>{rol.name}</Label>
                </Table.Cell>
                <Table.Cell>
                  {rol.description}
                </Table.Cell>
                {/* <Table.Cell>
                  {rol.key}
                </Table.Cell> */}
                <Table.Cell>
                  {rol.permissions?.length || 0}
                </Table.Cell>
                <Table.Cell>
                  <div style={{display: "flex", gap: "5px"}}>
                    <div>
                      <UpdateRole refreshFunction={getData} dataInfo={rol} defaultRoles={defaultRoles} />
                    </div>
                    <div>
                      <RemoveRoles refreshFunction={getData} dataInfo={rol} />
                    </div>
                  </div>
                  </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </TabContainer>
      {/* <AddAdminModal
      modalState={addAdminModalState}
      setModalState={value => setAddAdminModalState(value)}
  /> */}
    </DashboardTemplate>
  );
};

export default RolesView;
