import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Label, Select } from "semantic-ui-react";
import { advisorAPI } from "../../../../apis/AdvisorsAPI";
import { fetchCurrentCompany } from "../../../../actions/company.actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import TypesConfiguration from "./typesConfiguration";
import TableConfiguration from "./tablesConfiguration";
import TableConfigurationCreate from "./tablesConfiguration/Create";

const IntegrationSalesforce = ({ activeMenu }) => {
  const dispatch = useDispatch();
  const companyProfile = useSelector((state) => state.currentCompany);
  const configuration = companyProfile?._integrations?.salesforce;
  const [loader, setLoader] = useState(false);

  const handleEnable = async () => {
    setLoader(true);
    try {
      const enabled = configuration.enabled;
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        "_integrations.salesforce.enabled": !enabled,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
    } catch (error) {
      console.log({ error });
      alert("Something it's wrong, try again please...");
    }
    setLoader(false);
  };

  const handleChangeTab = async (type) => {
    setLoader(true);
    try {
      const tab = configuration.showMapper[type];
      await advisorAPI.put(`/companies/${companyProfile._id}`, {
        [`_integrations.salesforce.showMapper.${type}`]: !tab,
      });
      await dispatch(fetchCurrentCompany(companyProfile._id));
    } catch (error) {
      console.log({ error });
      alert("Something it's wrong, try again please...");
    }
    setLoader(false);
  };

  return (
    <>
      {activeMenu === "integration_salesforce" && (
        <>
          {/* <div>
            <h2>Configurations</h2>
            <div className="flex-gap-5">
              <div>
                <Button color={configuration.enabled ? "green" : "red"} onClick={handleEnable} loading={loader}>
                  Enabled: {configuration.enabled ? "Yes" : "No"}
                </Button>
              </div>
            </div>
          </div> */}
          <div>
            <h2>Show Mapper Configuration</h2>
            <div className="flex-gap-5">
              {["Personal", "Family", "Assets"].map((type, index) => (
                <div key={index}>
                  <Button color={configuration.showMapper[type] ? "green" : "red"} onClick={() => handleChangeTab(type)} loading={loader}>
                    Show Tab {type}: {configuration.showMapper[type] ? "Yes" : "No"}
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <h2>More Information</h2>
            <div className="flex-gap-5">
              <Label size="large">Mapper saved: {configuration.savedMapper ? "🟢 Yes" : "🔴 No"}</Label>
              <Label size="large">Login At: {configuration.loginAt? moment(configuration.loginAt).toString() : "No date..."}</Label>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <div className="flex-gap-5" style={{alignItems: "center", marginBottom:"10px"}}>
              <div>
                <h2>Tables Configuration</h2>
              </div>
              <div>
                <TableConfigurationCreate companyProfile={companyProfile} />
              </div>
            </div>
            <div>
              <TableConfiguration companyProfile={companyProfile} />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <h2>Mapper Configuration</h2>
            <div className="flex-gap-5" style={{ gap: "25px" }}>
              <div>
                <TypesConfiguration companyProfile={companyProfile} propType={"relationship"} />
              </div>
              <div>
                <TypesConfiguration companyProfile={companyProfile} propType={"assetTypes"} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default IntegrationSalesforce;
