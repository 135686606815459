import { useEffect, useState } from "react";
import { Form, Icon, Input, Label, Segment, Select } from "semantic-ui-react";

const FormAdmin = ({ info, setInfo, sysRoles, isEmailDisabled = false, isFirstNameDisabled = false, isLastNameDisabled = false }) => {
  const [firstName, setFirstName] = useState(info?.firstName || "");
  const [lastName, setLastName] = useState(info?.lastName || "");
  const [email, setEmail] = useState(info?.email || "");
  const [roles_ids, setRolesIds] = useState(info?.roles_ids || []);

  /* const handleSelectSysRoles = (value) => {
    console.log({value})
    if(!value) return
    let newArraySysRoles = JSON.parse(JSON.stringify(roles_ids));
    if (newArraySysRoles.includes(value)) {
      newArraySysRoles = newArraySysRoles.filter((rol) => rol !== value);
    } else {
      newArraySysRoles.push(value);
    }
    setRolesIds(newArraySysRoles);
  }; */

  useEffect(() => {
    setInfo({firstName, lastName, roles_ids, email });

    //eslint-disable-next-line
  }, [firstName, lastName, roles_ids, email]);

  return (
    <div>
      <Segment>
        <Form>
          <Form.Field
            control={Input}
            fluid
            icon="user"
            iconPosition="left"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            disabled={isFirstNameDisabled}
          />

          <Form.Field
            control={Input}
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
            disabled={isLastNameDisabled}
          />
          <Form.Field
            control={Input}
            fluid
            icon="at"
            iconPosition="left"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            disabled={isEmailDisabled}
          />

          <p>Select Roles:</p>
          <Form.Group widths="equal">
            <Form.Field
              fluid
              control={Select}
              options={sysRoles.map((rol) => {
                return { key: rol._id, value: rol._id, text: `${rol.name}` };
              })}
              value={roles_ids}
              placeholder="Select Roles"
              multiple={true}
              onChange={(e, elem) => setRolesIds(elem.value)}
            />
          </Form.Group>
        </Form>
      </Segment>
    </div>
  );
};

export default FormAdmin;
