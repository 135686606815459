import { useEffect } from "react";
import { Form, Segment, Input, TextArea, Select, Dropdown, Label } from "semantic-ui-react";
import * as helper from "../../helpers";

const FormSaml = ({ info, setInfo, companies = [] }) => {

  const rute = process.env.REACT_APP_AUTH_API_URL;
  const setChangeValue = (value, prop) => {
    const nextInfo = JSON.parse(JSON.stringify(info));

    if(prop === "serviceSlug"){
      value = helper.string_to_slug({ str: value || "", finalCharacterAdmited:"-" });
    }

    nextInfo[prop] = value;
    nextInfo["callbackUrl"] = `${rute}/saml/login/callback`;
    setInfo(nextInfo);
  };

  useEffect(() => {
    let slug = helper.string_to_slug({ str: info.name || "" });
    setChangeValue(slug, "serviceSlug");

    // eslint-disable-next-line
  }, [info.name]);

  useEffect(() => {
    const rute = process.env.REACT_APP_AUTH_API_URL;
    const finalLoginUrl = `${rute}/saml/login/${info.serviceSlug}`;
    setChangeValue(finalLoginUrl, "urlToLogin");
    // eslint-disable-next-line
  }, [info.serviceSlug]);

  return (
    <>
      <Segment>
        <Form>

          <div>Company: </div>
          <Form.Field
            control={Dropdown}
            search
            selection
            fluid
            placeholder="Company"
            value={info.company}
            onChange={(_, data) => setChangeValue(data.value, "company")}
            options={companies.map((company) => ({
              key: company._id,
              value: company._id,
              text: `${company.name}`,
            }))}
          />

          <div>Name: </div>
          <Form.Field control={Input} fluid placeholder="Name" value={info.name} onChange={(e) => setChangeValue(e.currentTarget.value, "name")} />

          <div>Custom Slug: </div>
          <Form.Field
            control={Input}
            fluid
            placeholder="Custom Slug"
            value={info.serviceSlug}
            onChange={(e) => setChangeValue(e.currentTarget.value, "serviceSlug")}
          />

          <div>Entity: </div>
          <Form.Field
            control={Input}
            fluid
            placeholder="example: Salesforce"
            value={info.entity}
            onChange={(e) => setChangeValue(e.currentTarget.value, "entity")}
          />
        </Form>
      </Segment>

      <Segment>
        <Form>

        <div>Entry Point: </div>
          <Form.Field
            control={Input}
            fluid
            placeholder="Entry Point"
            value={info.entryPoint}
            onChange={(e) => setChangeValue(e.currentTarget.value, "entryPoint")}
          />

          <div>Issuer: </div>
          <Form.Field control={Input} fluid placeholder="Issuer" value={info.issuer} onChange={(e) => setChangeValue(e.currentTarget.value, "issuer")} />


          <div>Cert: </div>
          <Form.Field control={TextArea} fluid placeholder="Cert" value={info.cert} onChange={(e) => setChangeValue(e.currentTarget.value, "cert")} />
        </Form>
      </Segment>

      <Segment>
        <div>
          Callback URL (click to copy):
          {info.serviceSlug ? (
            <div className="clickleable" onClick={() => helper.copyToClipboard(info.callbackUrl)}>
              {info.callbackUrl}
            </div>
          ) : (
            <div>Enter a name please</div>
          )}
        </div>
        <div style={{marginTop: "10px"}}>
          URL to Login (click to copy):
          {info.serviceSlug ? (
            <div className="clickleable" onClick={() => helper.copyToClipboard(info.urlToLogin)}>
              {info.urlToLogin}
            </div>
          ) : (
            <div>Enter a name please</div>
          )}
        </div>
      </Segment>
    </>
  );
};

export default FormSaml;
