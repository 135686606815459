import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentCompanyRequests } from '../../../actions/company.actions';
import { v4 as uuid } from 'uuid';
import { Table, Header, Input, Dropdown, Button, Icon } from 'semantic-ui-react';
import { advisorAPI } from '../../../apis/AdvisorsAPI';

const CompanyRequestedCredits = ({ activeMenu }) => {
  const dispatch = useDispatch();
  const companyProfile = useSelector((state) => state.currentCompany);
  const allRequests = useSelector((state) => state.currentCompanyRequests);

  const advisorsOptions = [{ value: '', text: 'All' }]
  companyProfile?.advisorList?.forEach(itm => {advisorsOptions.push({key: itm._id, value: itm._id, text: itm.fullName})});

  const allowedViews = {
    fulltaxRequests: {
      title: 'Fulltax',
      area: 'fulltax'
    },
    estateRequests: {
      title: 'Estate Snapshot',
      area: 'estate'
    },
    homeRequests: {
      title: 'Home Snapshot',
      area: 'home'
    },
    autoRequests: {
      title: 'Auto Snapshot',
      area: 'auto'
    },
  };
  const currentArea = allowedViews?.[activeMenu]?.area || undefined;
  const isFulltax = currentArea === "fulltax"
  //console.log({isFulltax, currentArea})

  const today = moment();
  const minDate = moment('2023-01-01');

  const [startDate, setStartDate] = useState(allRequests?.filters?.[currentArea]?.start || moment().subtract(30, 'days').format('YYYY-MM-DD').toString());
  const [finishDate, setFinishDate] = useState(allRequests?.filters?.[currentArea]?.end || moment().format('YYYY-MM-DD').toString());
  const [advisor, setAdvisor] = useState(allRequests?.filters?.[currentArea]?.advisor || '');

  const changeDate = (type, val) => {
    if (type === 'start') {
      setStartDate(val)

      /* const finishDate = moment(val).add(30, 'days');
      const finishDateString = finishDate.isSameOrBefore(today)
        ? finishDate.format('YYYY-MM-DD').toString()
        : today.format('YYYY-MM-DD').toString();

      setFinishDate(finishDateString); */
    }

    if (type === 'finish') {
      setFinishDate(val)

      /* const startDate = moment(val).subtract(30, 'days');
      const startDateString = startDate.isSameOrAfter(minDate)
        ? startDate.format('YYYY-MM-DD').toString()
        : minDate.format('YYYY-MM-DD').toString();

      setStartDate(startDateString); */
    }
  };

  const filterData = async () => {
    const newData = {...allRequests};

    const params = `companyId=${companyProfile._id}&area=${currentArea}&periodStart=${startDate}&periodEnd=${finishDate}${advisor ? `&advisorId=${advisor}` : ''}`;
    const url = `/companies/upload-credits/detail?${params}`;
    const { data } = await advisorAPI.get(url);

    newData[currentArea] = data[currentArea];

    if (!newData.filters) newData.filters = {};
    if (!newData.filters[currentArea]) newData.filters[currentArea] = {}

    newData.filters[currentArea].start = startDate;
    newData.filters[currentArea].end = finishDate;
    if (advisor) newData.filters[currentArea].advisor = advisor;

    dispatch(setCurrentCompanyRequests(newData));
  };

  const toggleActivityStatus = async (activityId, status="active") => {
    try {
      await advisorAPI.put(`/activity/${activityId}/toggle-status`, {
        status
      });
    } catch (e) {
      console.error(e);
    }
    await filterData();
  };

  const BuildedRow = ({ data }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [loaderStatus, setLoaderStatus] = useState(false)

    const changeActivityStatus = async (status) => {
      setLoaderStatus(true)
      try {
        if(status==="deleted"){
          // eslint-disable-next-line no-restricted-globals
          if(!confirm("Are you sure to delete the activity?")){
            setLoaderStatus(false)
            return
          }
        }
        await toggleActivityStatus(data._id, status)
      } catch (error) {
        console.error(error);
      }
      setLoaderStatus(false)
    }

    const dictionaryStatus = {
      active: "✅",
      discarded: "⚠️",
      deleted: "❌",
    }

    //console.log('data:', data);
    return(
      <Table.Row>
        <Table.Cell>{data.advisor}</Table.Cell>
        <Table.Cell>{data.client}</Table.Cell>
        <Table.Cell>{data.chargeType}</Table.Cell>
        <Table.Cell>{data.uploadType}</Table.Cell>
        <Table.Cell>{moment(data.date).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
        <Table.Cell>{data.documents.map((d, index) => (<p key={index} style={{margin:0}}>{d}</p>))}</Table.Cell>
        {isFulltax &&
          <Table.Cell width={2}>{data?.reasonOfFailure?.length > 50 ? `${data.reasonOfFailure.slice(0,50)}...`: data.reasonOfFailure}</Table.Cell>
        }
        <Table.Cell>
          <Dropdown
            trigger={
              <Button icon onClick={() => setOpenDropdown(!openDropdown)}>
                {loaderStatus ? (<>
                  <Icon loading name={"spinner"}/>
                </>):(<>
                  {dictionaryStatus[data.status]}
                </>)}
              </Button>
            }
            close
            pointing="right" open={openDropdown}
            icon={false}
          >
            <Dropdown.Menu>
              {["discarded","deleted"].includes(data.status) && (
                <Dropdown.Item>
                  <div onClick={() => changeActivityStatus("active")}>
                    ✅ Active
                  </div>
                </Dropdown.Item>
              )}
              {["active","deleted"].includes(data.status) && (
                <Dropdown.Item>
                  <div onClick={() => changeActivityStatus("discarded")}>
                    ⚠️ Discard
                  </div>
                </Dropdown.Item>
              )}
              {["active","discarded"].includes(data.status) && (
                <Dropdown.Item>
                  <div onClick={() => changeActivityStatus("deleted")}>
                    ❌ Delete
                  </div>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Table.Cell>
      </Table.Row>
    );
  };

  useEffect(() => {
    setAdvisor(allRequests?.filters?.[currentArea]?.advisor || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentArea]);

  return (<>
    {Object.keys(allowedViews).includes(activeMenu) && <>
      <Header as="h1">{allowedViews[activeMenu].title} Requests/Uploads</Header>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr auto',
          gap: '1em',
        }}
      >
        <Input
          value={startDate}
          onChange={(_, value) => changeDate('start', value.value)}
          type="date"
          label="From:"
          min={minDate.format('YYYY-MM-DD').toString()}
          max={moment().subtract(30, 'days').format('YYYY-MM-DD').toString()}
        />
        <Input
          value={finishDate}
          onChange={(_, value) => changeDate('finish', value.value)}
          type="date"
          label="To:"
          max={today.format('YYYY-MM-DD').toString()}
        />
        <Dropdown
          placeholder="Advisor:"
          options={advisorsOptions}
          value={advisor}
          onChange={(_, val) => { setAdvisor(val.value) }}
          fluid
          selection
          search
        />
        <Button onClick={filterData}>Filter</Button>
      </div>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Advisor</Table.HeaderCell>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Charge Type</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Documents</Table.HeaderCell>
            {isFulltax &&
              <Table.HeaderCell>Reason of failure</Table.HeaderCell>
            }
            <Table.HeaderCell>Discount/Count Credit</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {allRequests?.[currentArea]?.length > 0 && <>
          <Table.Body>
            {allRequests[currentArea].map((itm, index) => <BuildedRow key={index} data={itm} />)}
          </Table.Body>
        </>}

      </Table>
    </>}
  </>);
}

export default CompanyRequestedCredits;
