import { useSelector } from "react-redux"
import { Redirect, /* useParams */ } from "react-router-dom";

export const InitialDashboardRouteDecider = () => {
    const me = useSelector(state => state.me);
    console.log({me})

    if(Object.keys(me).length < 1){
        return <div>Loading...</div>
    }

    const routes = [
        {
            name: "Advisors",
            to: "/dashboard/advisors",
            permission: "advisors:advisor_view"
        },
        {
            name: "Clients",
            to: "/dashboard/clients",
            permission: "clients:client_view"
        },
        {
            name: "Companies",
            to: "/dashboard/companies",
            permission: "companies:company_view"
        },
        {
            name: "Sales",
            to: "/dashboard/sales",
            permission: "sales:sales_view"
        },
        {
            name: "Usage Metrics",
            to: "/dashboard/metrics",
            permission: "metrics:metrics_view"
        },
        {
            name: "Manual Input",
            to: "/dashboard/manual-input",
            permission: "manual_inputs:manual_manual_input_view"
        },
        {
            name: "Resources",
            to: "/dashboard/resources",
            permission: "resourses:resourse_view"
        },
        {
            name: "Admin",
            to: "/dashboard/admin",
            permission: "admin:admin_view"
        },
        {
            name: "Roles",
            to: "/dashboard/admin-roles",
            permission: "roles:rol_view"
        },
        {
            name: "Admin QuickBooks",
            to: "/dashboard/quickbooks",
            permission: "admin_quickbooks:admin_quickbook_view"
        },
        {
            name: "Snapshots",
            to: "/dashboard/advisors-snapshots",
            permission: "snapshots:snapshots_view"
        },
    ]

    for(const route of routes){
        if(me?.permissions?.includes(route.permission)){
            return <Redirect to={`${route.to}${window.location.search}`} />
        }
    }

    return <div>You don't have any permission to show :c</div>
}