import { useState } from "react";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import FormRole from "./Form";
import { sysAPI } from "../../apis/SysAPI";
import { useSelector } from "react-redux";


const RemoveRoles = ({refreshFunction, dataInfo}) => {
  const me = useSelector((state) => state.me);
  const [openModal, setOpenModal] = useState(false);
  const [validate, setValidate] = useState("")

  const handleRemove = async () => {
    try {
      const {data} = await sysAPI.delete(`/sys-roles/${dataInfo._id}`)
      refreshFunction();
      setOpenModal(false)
    } catch (error) {
      console.log({error})
      alert(`Error: ${error?.response?.data?.message || error.message}`)
    }
  }

  if (!me.permissions.includes("roles:rol_delete")) {
    return <div></div>;
  }

  return (
    <div>
      <Button icon onClick={() => setOpenModal(true)}>
        <Icon name="remove" />
      </Button>

      <Modal size='tiny' open={openModal} onClose={() => setOpenModal(false)} onOpen={() => setOpenModal(true)}>
        <Modal.Header>
          Remove role {dataInfo.name}
        </Modal.Header>
        <Modal.Content>
          <div>
            <p>For remove this role, just type "{dataInfo.name}" tu unlock the button Delete</p>
            <Input
              fluid
              value={validate}
              onChange={(e) => setValidate(e.target.value)}
              placeholder={`Type: ${dataInfo.name}`}
            />
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button onClick={handleRemove} color="red" disabled={validate !== dataInfo.name} >Delete</Button>
        </Modal.Actions>
      </Modal>

    </div>
  );
};
export default RemoveRoles;
