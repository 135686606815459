import { useEffect, useState } from "react";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { sysAPI } from "../../../apis/SysAPI";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentAdvisorProfile } from "../../../actions/advisor.actions";
import { advisorAPI } from "../../../apis/AdvisorsAPI";

const ChangeNameOrEmail = ({advisorProfile}) =>{
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const [openModal, setOpenModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");

    const handleSave = async () => {
        setLoader(true)
        try {
            //const {data} = await sysAPI.put(`/advisor/${advisorProfile._id}/update-name-or-email`, {fullName, email})
            const {data} = await advisorAPI.patch(`/${advisorProfile._id}`, {fullName, email});
            setOpenModal(false)
            dispatch(fetchCurrentAdvisorProfile(advisorProfile._id));
        } catch (error) {
            const messageError = error?.response?.data?.message || error?.message || "Something was wrong"
            console.log({error})
            alert(messageError)
        }
        setLoader(false)
    }

    const defaultContig = () => {
        setFullName(advisorProfile?.fullName)
        setEmail(advisorProfile?.email)
    }

    useEffect(() => {
        defaultContig();

        // eslint-disable-next-line
    },[advisorProfile, openModal]);

    return <>
        {(me.permissions.includes("advisor:change_fullname") || me.permissions.includes("advisor:change_email")) &&
            <Button icon labelPosition='left' color="teal" onClick={() => setOpenModal(!openModal)}>
                <Icon size='large' name='pencil' />
                Change Name Or Email
            </Button>
        }

        <Modal open={openModal} size="tiny">
            <Modal.Header>Change Name Or Email</Modal.Header>
            <Modal.Content>
                {me.permissions.includes("advisor:change_fullname") &&
                    <div>
                        <div>
                            FullName:
                        </div>
                        <div>
                            <Input value={fullName} onChange={(_,{value}) => setFullName(value)} fluid />
                        </div>
                    </div>
                }
                {me.permissions.includes("advisor:change_email") &&
                    <div style={{marginTop: "10px"}}>
                        <div>
                            Email:
                        </div>
                        <div>
                            <Input value={email} onChange={(_,{value}) => setEmail(value)} fluid />
                        </div>
                    </div>

                }
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpenModal(false)}>Close</Button>
                <Button onClick={() => handleSave()} color="green" loading={loader}>Save</Button>
            </Modal.Actions>
        </Modal>
    </>
} 

export default ChangeNameOrEmail;