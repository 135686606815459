/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import {
    Button,
    Tab,
    Input,
    Dropdown,
} from "semantic-ui-react"
import { sysAPI } from "../../../apis/SysAPI";
import { ComponentTemplate } from "../../../templates/ComponentTemplate";
import LoaderCustom from "./LoaderCustom";
import RequestsTable from "./RequestsTable";

const EstateManualInputRequestsTab = () => {
    const [requests, setRequests] = useState([]);
    const [pending, setPending] = useState([]);
    const [inReview, setInReview] = useState([]);
    const [peerReview, setPeerReview] = useState([]);
    const [onHold, setOnHold] = useState([]);
    const [ready, setReady] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [changeMaps, setChangeMaps] = useState(0);
    const [requestDate, setRequestDate] = useState('');
    const [sort, setSort] = useState('requestDate');
    const [direction, setDirection] = useState('ASC');
    // eslint-disable-next-line
    const [initDate, setInitDate] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [companySelected, setCompanySelected] = useState('');
    const [pocsLists, setPocsLists] = useState([]);
    const [pocSelected, setPocSelected] = useState('');
    const [refreshList, setRefreshList] = useState(0);

    const getRequests = (params = '') => {
        sysAPI.get(`/estate-manual-input/requests${params}`).then(response => {
            const { requests } = response.data;
            if (!requests) {
                setRequests([]);
                return true;
            }

            if (requests.length === 0) {
                return window.alert('No matches found for your current filters.');
            }

            setRequests(requests);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handlerFilterByDate = () => {
        setIsLoading(true);
        let filter = '';
        if (companySelected !== '' && companySelected !== 'N/A') {
            filter = `tabFilter=company&tabValue=${companySelected}`;
        }

        if (pocSelected !== '' && pocSelected !== 'N/A') {
            filter = `tabFilter=poc&tabValue=${pocSelected}`;
        }

        const sortType = sort !== '' ? `filter=${sort}&` : '';
        const sortDirection = sort !== '' ? `order=${direction}&` : '';
        const sortDate = requestDate !== '' ? `initDate=${requestDate}&` : '';

        const filters = `?${sortType}${sortDirection}${sortDate}${filter}`;

        getRequests(filters);
    }

    useEffect(() => {
        setIsLoading(true);
        let filter = '';
        if (companySelected !== '' && companySelected !== 'N/A') {
            filter = `tabFilter=company&tabValue=${companySelected}`;
        }
        if (pocSelected !== '' && pocSelected !== 'N/A') {
            filter = `tabFilter=poc&tabValue=${pocSelected}`;
        }

        const sortType = sort !== '' ? `filter=${sort}&` : '';
        const sortDirection = sort !== '' ? `order=${direction}&` : '';
        const sortDate = requestDate !== '' ? `initDate=${requestDate}` : '';

        const filters = `?${sortType}${sortDirection}${sortDate}${filter}`;

        getRequests(filters);
    }, [sort, direction, initDate]);

    useEffect(() => {
        if (refreshList > 0) {
            setIsLoading(true);
            let filter = '';
            if (companySelected !== '' && companySelected !== 'N/A') {
                filter = `tabFilter=company&tabValue=${companySelected}`;
            }
            if (pocSelected !== '' && pocSelected !== 'N/A') {
                filter = `tabFilter=poc&tabValue=${pocSelected}`;
            }

            const sortType = sort !== '' ? `filter=${sort}&` : '';
            const sortDirection = sort !== '' ? `order=${direction}&` : '';
            const sortDate = requestDate !== '' ? `initDate=${requestDate}` : '';

            const filters = `?${sortType}${sortDirection}${sortDate}${filter}`;

            getRequests(filters);
        }
    }, [refreshList]);

    useEffect(() => {
        if (requests.length >= 1) {
            const mapPending = requests.filter(r => r.status === 'Pending') || [];
            const mapInReview = requests.filter(r => r.status === 'In review') || [];
            const mapPeerReview = requests.filter(r => r.status === 'Peer review') || [];
            const mapOnHold = requests.filter(r => r.status === 'On Hold') || [];
            const mapReady = requests.filter(r => r.status === 'Ready') || [];

            setPending(mapPending);
            setInReview(mapInReview);
            setPeerReview(mapPeerReview);
            setOnHold(mapOnHold);
            setReady(mapReady);
        }
    }, [requests]);

    useEffect(() => {
        if (changeMaps >= 1) {
            setIsLoading(true);
            getRequests();
        }
    }, [changeMaps]);

    useEffect(() => {
        sysAPI.get(`/companies/emi`).then(response => {
            const { companiesList } = response.data;
            const list = companiesList.map(company => ({ key: company, text: company, value: company }))
            list.unshift({ key: 'all', text: 'All', value: '' });
            setCompaniesList(list);
        }).catch(err => {
            console.error(err);
        });

        sysAPI.get('/companies/pocs').then(response => {
            const pocs = response.data.map(advisor => ({ key: advisor._id, text: advisor.name, value: advisor.name}));
            pocs.unshift({ key: 'all', text: 'All', value: '' });
            setPocsLists(pocs);
        }).catch(err => {
            console.error(err);
        });

        setIsLoading(false);
    }, []);

    const countRequests = (status) => {
        return requests?.filter(r => r.status === status).length;
    }

    const panes = () => ([
        { menuItem: `Pending (${countRequests('Pending')})`, render: () => <RequestsTable requests={pending} stage='Pending' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `In Review (${countRequests('In review')})`, render: () => <RequestsTable requests={inReview} stage='In review' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `On Hold (${countRequests('On Hold')})`, render: () => <RequestsTable requests={onHold} stage='On Hold' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} refreshList={setRefreshList} /> },
        { menuItem: `Under Peer Review (${countRequests('Peer review')})`, render: () => <RequestsTable requests={peerReview} stage='Peer review' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `Ready (${countRequests('Ready')})`, render: () => <RequestsTable requests={ready} stage='Ready' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
    ]);

    return (
        <ComponentTemplate scope={'estate_manual_input'} permission="manual_inputs:estate_manual_input_view">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '12px',
                }}
            >
                <Dropdown
                    placeholder='Filter by Company'
                    selection
                    search
                    options={companiesList}
                    value={companySelected}
                    onChange={(e, { value }) => {setPocSelected(''); setCompanySelected(value)}}
                />
                <Dropdown
                    placeholder='Filter by POC'
                    selection
                    search
                    options={pocsLists}
                    value={pocSelected}
                    onChange={(e, { value }) => {setCompanySelected(''); setPocSelected(value)}}
                />
                <Input
                    value={requestDate}
                    onChange={e => setRequestDate(e.currentTarget.value)}
                    icon='calendar alternate outline'
                    iconPosition='left'
                    type='date'
                    min='2022-05-31'
                />
                <Button onClick={handlerFilterByDate}>Filter</Button>
            </div>

            <Tab
                menu={{ secondary: true, pointing: true }}
                panes={panes()}
            />
            {isLoading && <LoaderCustom />}
        </ComponentTemplate>
    )
}

export default EstateManualInputRequestsTab;