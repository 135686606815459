import { v4 as uuid } from 'uuid';
import { Accordion, AccordionContent, AccordionTitle, Button, Container, Grid, Icon, Menu, Message } from "semantic-ui-react";
import { TabContainer } from "../../components/shared/TabContainer"
import { DashboardTemplate } from "../../templates/DashboardTemplate"
import { useDispatch, useSelector } from 'react-redux';
import { addEstateManualInputDocument, fetchManualInputDataPoints, finishReview, removeEstateManualInputDocument, resetEstateManualInputAssets, resetEstateManualInputDocuments, resetEstateManualInputFields, saveManualInputForLater } from '../../actions/estate-manual-input.actions';
import { useEffect, useState } from "react";
import { authAPI } from '../../apis/AuthAPI';
import { Redirect, useParams } from 'react-router-dom';
import { fetchCurrentClient } from "../../actions/client.actions";
import { sysAPI } from "../../apis/SysAPI";
import { Trust } from '../../components/forms/TrustForm';
import { General } from '../../components/forms/General';
import { PoaForm } from '../../components/forms/PoaForm';
import { HealthCareForm } from '../../components/forms/HealthCareForm';
import { Will } from "../../components/forms/WillForm";
import { FooterOptions, HeaderOptions } from './Options';

const FormContainer = (props) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '80%' }}>
                {props.children}
            </div>
        </div>
    )
}

const EstateManualInputV4 = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const me = useSelector(state => state.me);
    const client = useSelector(state => state.currentClient);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const estateManualInputDocuments = useSelector(state => state.estateManualInputDocuments);
    const estateManualInputChangeDocuments = useSelector(state => state.estateManualInputChangeDocuments);
    const estateManualInput = useSelector(state => state.estateManualInput);

    const [clientId, setClientId] = useState('');
    const [assetIndex, setAssetIndex] = useState(1);
    const versions = [1,2,3,4];
    const [activeTab, setActiveTab] = useState("");
    const [allowSend, setAllowSend] = useState(false);
    const [manualInputs, setManualInputs] = useState([])
    const [activeIndex,setActiveIndex] = useState(-1);
    const [loaderGetClients, setLoaderGetClients] = useState(false)
    const [loadingOpenTab, setLoadingOpenTab] = useState(false)
    const [loaderSaveForLater, setLoaderSaveForLater] = useState(false)
    const [loaderFinishReview, setLoaderFinishReview] = useState(false)


    const getManualInputs = async () => {
        setLoaderGetClients(true)
        const { clientId: client_id } = params;
        console.log({client_id})
        setClientId(client_id);
        dispatch(fetchCurrentClient(client_id));
        const manual_inputs = []
        for(const index of [1,2]){
            try {
                const {data} = await sysAPI.get(`/generic-manual-input?clientId=${client_id}&area=${"estate"}&index=${index}`)
                if([4].includes(data?.version)) {
                    manual_inputs.push(data)
                }else{ 
                    alert(`${data.clientDisplayName} is not version 4`)
                }
            } catch (error) {
                console.log({error})
            }
        }
        setActiveIndex(manual_inputs[0]?.index-1)
        setManualInputs(manual_inputs)
        setLoaderGetClients(false)
    }

    const addTypeBtnClick = (type, isJoint = false) => {
        const id = uuid();
        dispatch(addEstateManualInputDocument(type, id, isJoint));
    }

    const removeTrustBtn_Click = (id) => {
        return () => dispatch(removeEstateManualInputDocument(id));
    };

    const saveForLaterBtn_Click = async (showAlert = true, isChangeTab = -1) => {
        if(loadingOpenTab){
            alert("Please wait...");
            return
        }

        setLoaderSaveForLater(true)
        setLoadingOpenTab(true)

        if(estateManualInputRequest?.status !== 'Ready'){
            let askIfWantSaveBoforeChange = showAlert;

            if(!showAlert && isChangeTab !== -1 && activeTab !== ""){
                // eslint-disable-next-line no-restricted-globals
                askIfWantSaveBoforeChange = confirm(`You are changin of tab, Do you want save for later?`);
            }

            if(askIfWantSaveBoforeChange){
                console.log("Saving changes")
                await dispatch(saveManualInputForLater(clientId, assetIndex, "estate", showAlert))
            }
        }

        if(isChangeTab+1 > 0){
            setActiveIndex(isChangeTab)
        }
        setLoaderSaveForLater(false)

        //* Add a time because, if you change the tabs too fast, posibble lost data and we can't restore.
        setTimeout(() =>{
            setLoadingOpenTab(false)
        }, isChangeTab !== -1 ? 4000: 1)
    }

    const finishReviewBtn_Click = async () => {
        setLoaderFinishReview(true)
        const confirm = window.confirm('This action will set as concluded the reviewing phase. Do you want to confirm this action?');
        if (confirm) {
            await dispatch(finishReview(clientId, assetIndex))
            window.location.replace(`/dashboard/manual-input`)
        }
        setLoaderFinishReview(false)
    }

    const verifyRequiredTrustFields = () => {
        const trustsDocs = [];
        let canSend = false;

        Object.keys(estateManualInputDocuments).forEach(itm => {
            if (estateManualInputDocuments?.[itm]?.type === 'Trust') {
                trustsDocs.push(itm);
            }
        });

        if (trustsDocs.length === 0) {
            canSend = true
        } else {
            let allowCounter = 0;
            trustsDocs.forEach(itm => {
                const type = estateManualInput?.[itm]?.type || '';
                const subtype = estateManualInput?.[itm]?.subtype || '';
                if (type?.length > 0 && subtype?.length > 0) {
                    allowCounter += 1;
                }
            });

            if (allowCounter === trustsDocs.length) {
                canSend = true;
            }
        }

        return canSend;
    };

    const ButtonMoveToJoint = ({docId}) => {
        const handleClick = async () => {
            if(estateManualInputRequest?.status === 'Ready'){
                alert("We can't move because is ready this request.")
                return
            }
            // eslint-disable-next-line no-restricted-globals
            if(!confirm("Are you sure to move this trust to the Joint tab? ")){
                return
            }

            await dispatch(addEstateManualInputDocument("Trust", docId, true));
            setTimeout(async () => {
                await dispatch(saveManualInputForLater(clientId, assetIndex, "estate", false))
                setActiveIndex(2)
            },1000)
        }
        return <>
            {manualInputs.length >= 2 && <>
                <Button size='mini' color="teal" onClick={()=>handleClick()}>Move to Joint</Button>
            </>}
        </>
    }

    useEffect(() => {
        const trustRequired = verifyRequiredTrustFields();

        setAllowSend(trustRequired);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estateManualInput]);

    useEffect(() => {
        if([0,1,2].includes(activeIndex)){
            setActiveTab("")
            const assetIndex__ = activeIndex === 2 ? 1 : activeIndex +1
            console.log({clientId, assetIndex__})
            setAssetIndex(assetIndex__);
            dispatch(resetEstateManualInputFields());
            dispatch(resetEstateManualInputAssets());
            dispatch(resetEstateManualInputDocuments());

            dispatch(fetchManualInputDataPoints(clientId, assetIndex__));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex]);

    useEffect(() => {
        getManualInputs()
        /* const manualInputDataPoints__ = fetchManualInputDataPoints(clientId, 1)
        dispatch(fetchManualInputDataPoints(clientId, 1));
        dispatch(fetchCurrentClient(client_id)); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tabs = {
        // will: { label: 'Will', component: <Will />, id: 'will' }
        // eslint-disable-next-line
        ...(estateManualInputDocuments && Object.keys(estateManualInputDocuments).reduce((docs, docId, i) => ({ ...docs, [docId]: {
            label: (() => {
                if (estateManualInputDocuments?.[docId]?.type === 'Trust') {
                    const name = client?.estateContactsList
                        ?.find(c => c._id === estateManualInput?.[`${docId}`]?.name)?.firstName
                        || `${estateManualInputDocuments[docId].type} ${i + 1}`;
                    const type = estateManualInput?.[docId]?.type;
                    return type ? `${name} [${type}]` : name;
                }
                else if (estateManualInputDocuments?.[docId]?.type === 'Will') {
                    const ownerId = estateManualInput?.[docId]?.willOwner;
                    const owner = client?.estateContactsList?.find(c => c._id === ownerId);
                    return owner ? `${owner.firstName} ${owner.lastName}'s Will` : `Will ${i + 1}`;
                }
                else if (versions.includes(estateManualInputRequest.estateSnapshotVersion) && estateManualInputDocuments?.[docId]?.type === 'POA') {
                    return 'POA'
                }
                else if (versions.includes(estateManualInputRequest.estateSnapshotVersion) && estateManualInputDocuments?.[docId]?.type === 'Health Care') {
                    return 'Health Care'
                }
                else if (versions.includes(estateManualInputRequest.estateSnapshotVersion) && estateManualInputDocuments?.[docId]?.type === 'General') {
                    return 'General'
                }
            })(),
            component: estateManualInputDocuments[docId].type === 'Trust'
                ? <Trust docId={docId} docType={estateManualInputDocuments[docId].type} isJoint={estateManualInputDocuments[docId].isJoint} ButtonMoveToJoint={<ButtonMoveToJoint docId={docId} />} />
                : estateManualInputDocuments[docId].type === 'Will'
                    ? <Will docId={docId} />
                    : estateManualInputDocuments[docId].type === 'POA'
                        ? <PoaForm docId={docId} />
                        : estateManualInputDocuments[docId].type === 'Health Care'
                            ? <HealthCareForm docId={docId} />
                            : <General docId={docId}/>,
            id: docId,
            // eslint-disable-next-line
        } }), {}) || {}),
    };

    let panes = Object.keys(tabs).map(docId => {
        return {
            id: tabs[docId].id,
            menuItem: tabs[docId].label,
            render: <FormContainer>{tabs[docId].component}</FormContainer>,
            type:estateManualInputDocuments?.[docId]?.type,
            isJoint:estateManualInputDocuments?.[docId]?.isJoint
        }
    });

    const showMoreV3 = estateManualInputRequest.estateSnapshotVersion >= 3;

    return <>
        <DashboardTemplate scope="manual_input">
            <TabContainer
                title={`Estate Manual Input for ${client?.clientIDFirstName} ${client?.clientIDLastName}`}
                subtitle='Form for Estate documents such as wills, trusts, ILIT, etc.'
                headerOptions={<HeaderOptions
                    estateManualInputRequest={estateManualInputRequest}
                    allowSend={allowSend}
                    saveForLaterBtn_Click={saveForLaterBtn_Click}
                    finishReviewBtn_Click={finishReviewBtn_Click}
                    clientId={clientId}
                    assetIndex={assetIndex}
                    loaderSaveForLater={loaderSaveForLater}
                    loaderFinishReview={loaderFinishReview}
                />}
            >
                {loaderGetClients && <>
                    Loading...
                </>}
                {!loaderGetClients && <>
                    {manualInputs.length > 0 ? <>
                        <Grid>
                            <Grid.Column width={4}>
                                <Accordion fluid styled>
                                    {manualInputs.map((manual, index_1) => (
                                        <div key={index_1}>
                                            <AccordionTitle
                                                active={activeIndex === index_1}
                                                onClick={() => saveForLaterBtn_Click(false, activeIndex === index_1 ? -1 : index_1)}
                                            >
                                                <Icon name='dropdown' /> {manual.clientDisplayName} {loadingOpenTab && <Icon loading name='spinner' />}
                                            </AccordionTitle>
                                            <AccordionContent active={activeIndex === index_1}>
                                                <Menu fluid vertical>
                                                    {panes.length > 0 ? <>
                                                        {panes.filter(e => !e.isJoint).map(pane =>
                                                            <Menu.Item name={pane.menuItem} active={activeTab === pane.id} onClick={() => { setActiveTab(pane.id)}} style={{boxShadow: estateManualInputChangeDocuments && `${pane.id}` in estateManualInputChangeDocuments ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none'}}>
                                                                {pane.menuItem}
                                                                {pane.menuItem !== 'General' && <Icon name='delete' onClick={removeTrustBtn_Click(pane.id)} />}
                                                            </Menu.Item>
                                                        )}
                                                    </> : <div style={{width: "100%", display: "flex", justifyContent:"center", padding:"10px"}}>
                                                            Loading...
                                                    </div>}
                                                </Menu>
                                                <Button onClick={() => addTypeBtnClick('Will')} style={{ marginBottom: '6px' }}>
                                                    Add will
                                                </Button>
                                                <Button onClick={() => addTypeBtnClick('Trust')} style={{ marginBottom: '6px' }}>
                                                    Add trust
                                                </Button>
                                                <Button onClick={() => addTypeBtnClick('POA')} style={{ marginBottom: '6px', display: showMoreV3 ? 'inline-block' : 'none' }} >
                                                    Add POA
                                                </Button>
                                                <Button onClick={() => addTypeBtnClick('Health Care')} style={{ marginBottom: '6px', display: showMoreV3 ? 'inline-block' : 'none' }} >
                                                    Add Health Care
                                                </Button>
                                            </AccordionContent>
                                        </div>
                                    ))}
                                    {manualInputs.length === 2 &&
                                        <div>
                                            <AccordionTitle
                                                disabled
                                                active={activeIndex === 2}
                                                onClick={() => saveForLaterBtn_Click(false, activeIndex === 2 ? -1 : 2)}
                                            >
                                                <Icon name='dropdown' /> Joint {loadingOpenTab && <Icon loading name='spinner' />}
                                            </AccordionTitle>
                                            <AccordionContent active={activeIndex === 2}>
                                                {activeIndex === 2 && <>
                                                    <Menu fluid vertical>
                                                        {panes.length > 0 ? <>
                                                            {panes.filter(e => e.isJoint).map(pane =>
                                                                <Menu.Item name={pane.menuItem} active={activeTab === pane.id} onClick={() => setActiveTab(pane.id)} style={{boxShadow: estateManualInputChangeDocuments && `${pane.id}` in estateManualInputChangeDocuments ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none'}}>
                                                                    {pane.menuItem}
                                                                    {pane.menuItem !== 'General' && <Icon name='delete' onClick={removeTrustBtn_Click(pane.id)} />}
                                                                </Menu.Item>
                                                            )}
                                                        </> : <div style={{width: "100%", display: "flex", justifyContent:"center", padding:"10px"}}>
                                                                Loading...
                                                        </div>}
                                                    </Menu>
                                                </>
                                                }
                                                <Button onClick={() => addTypeBtnClick('Trust', true)} style={{ marginBottom: '6px' }}>
                                                    Add Trust
                                                </Button>
                                            </AccordionContent>
                                        </div>
                                    }
                                </Accordion>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                {panes.find(p => p.id === activeTab)?.render}
                            </Grid.Column>
                        </Grid>
                        <FooterOptions
                            estateManualInputRequest={estateManualInputRequest}
                            allowSend={allowSend}
                            saveForLaterBtn_Click={saveForLaterBtn_Click}
                            finishReviewBtn_Click={finishReviewBtn_Click}
                            loaderSaveForLater={loaderSaveForLater}
                            loaderFinishReview={loaderFinishReview}
                        />
                    </> : <>
                        Not found manual inputs with version 4
                    </>}
                </>}
                {activeTab}
            </TabContainer>
        </DashboardTemplate>
    </>
}


export default EstateManualInputV4