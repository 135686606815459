/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import { v4 as uuid } from 'uuid';
import { DashboardTemplate } from '../templates/DashboardTemplate'
import { TabContainer } from '../components/shared/TabContainer'
import { Button, Container, Grid, Icon, Menu, Message } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addEstateManualInputDocument, fetchManualInputDataPoints, finishReview, removeEstateManualInputDocument, saveManualInputForLater } from '../actions/estate-manual-input.actions';
import { sysAPI } from '../apis/SysAPI';
import { FamilyMembersModal } from '../components/modal/FamilyMembersModal';
import { fetchCurrentClient } from '../actions/client.actions';
import { AssetsModal } from '../components/modal/AssetsModal';
import { authAPI } from '../apis/AuthAPI';
import { HomeForm } from '../components/forms/HomeForm';
import { UmbrellaForm } from '../components/forms/UmbrellaForm';
import { AutoForm } from '../components/forms/AutoForm';
import { InsuranceGeneralForm } from '../components/forms/InsuranceGeneralForm';

import { PropertiesModal } from '../components/modal/PopertiesModal';

const FormContainer = (props) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '80%' }}>
                {props.children}
            </div>
        </div>
    )
}

export const InsuranceSnapshot = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const [showFamilyModal, setShowFamilyModal] = useState(false);
    const [showEntitiesModal, setShowEntitiesModal] = useState(false);
    const [showPropertiesModal, setShowPropertiesModal] = useState(false);
    const [showAssetsModal, setShowAssetsModal] = useState(false);
    const [activeTab, setActiveTab] = useState('will');

    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const estateManualInputDocuments = useSelector(state => state.estateManualInputDocuments);
    const estateManualInputChangeDocuments = useSelector(state => state.estateManualInputChangeDocuments);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const me = useSelector(state => state.me);
    const client = useSelector(state => state.currentClient);

    const [clientId, setClientId] = useState('');
    const [assetIndex, setAssetIndex] = useState(1);
    const [area, setArea] = useState('');
    const [finished, setFinished] = useState(false);
    const [insuranceProviders, setInsuranceProviders] = useState([]);
    const [insuranceAssets, setInsuranceAssets] = useState([]);
    // const versions = [1, 2, 3];


    const getProperties = (client) => {
        sysAPI.get(`/insurance-snapshot/assets/${client}`)
            .then(response => {
                const home = [];
                const auto = [];
                if (response?.data?.home?.length > 0) {
                    response?.data?.home?.forEach(itm => {
                        home.push({
                            _id: itm._id,
                            title: itm.title,
                            index: itm.index,
                            type: 'Home',
                        });
                    });
                }
                if (response?.data?.auto?.length > 0) {
                    response?.data?.auto?.forEach(itm => {
                        auto.push({
                            _id: itm._id,
                            title: itm.title,
                            index: itm.index,
                            type: 'Auto',
                        });
                    });
                }
                setInsuranceAssets([...home, ...auto]);
            });
    };

    const tabs = {
        ...(estateManualInputDocuments && Object.keys(estateManualInputDocuments).reduce((docs, docId, i) => ({ ...docs, [docId]: {
            label: (() => {
                if (estateManualInputDocuments?.[docId]?.type === 'Home') {
                    const ownerId = estateManualInput?.[docId]?.willOwner;
                    const owner = client?.estateContactsList?.find(c => c._id === ownerId);
                    return owner ? `${owner.firstName} ${owner.lastName}'s Home` : `Home ${i + 1}`;
                }
                else if (estateManualInputDocuments?.[docId]?.type === 'Auto') {
                    const ownerId = estateManualInput?.[docId]?.willOwner;
                    const owner = client?.estateContactsList?.find(c => c._id === ownerId);
                    return owner ? `${owner.firstName} ${owner.lastName}'s Auto` : `Auto ${i + 1}`;
                }
                else if (estateManualInputDocuments?.[docId]?.type === 'Umbrella') {
                    return 'Umbrella'
                }
                else {
                    return 'General'
                }
            })(),
            component: estateManualInputDocuments[docId].type === 'Home'
                ? <HomeForm docId={docId} insuranceProviders={insuranceProviders} assets={insuranceAssets}/>
                : estateManualInputDocuments[docId].type === 'Auto'
                    ? <AutoForm docId={docId} insuranceProviders={insuranceProviders} assets={insuranceAssets}/>
                    : estateManualInputDocuments[docId].type === 'Umbrella'
                        ? <UmbrellaForm docId={docId} area={area} insuranceProviders={insuranceProviders} assets={insuranceAssets}/>
                        : <InsuranceGeneralForm docId={docId} area={area}/>,
            id: docId,
        } }), {}) || {}),
    };

    useEffect(() => {
        const { clientId, assetIndex, areaAsset } = params;
        setClientId(clientId);
        setArea(areaAsset);
        setAssetIndex(assetIndex || 1);
        dispatch(fetchManualInputDataPoints(clientId, assetIndex, areaAsset));
        dispatch(fetchCurrentClient(clientId));

        sysAPI.get('/insurance-providers/')
            .then(response => {
                setInsuranceProviders(response.data);
            });
        // getProperties(clientId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { clientId } = params;
        if (showPropertiesModal === false) getProperties(clientId);
    }, [showPropertiesModal]);

    const panes = Object.keys(tabs).map(
        docId => ({ id: tabs[docId].id, menuItem: tabs[docId].label, render: <FormContainer>{tabs[docId].component}</FormContainer> })
    );


    const addHomeBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputDocument('Home', id));
    };

    const addAutoBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputDocument('Auto', id));
    };

    const addUmbrellaBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputDocument('Umbrella', id));
    };

    const removeDocBtn_Click = (id) => {
        return () => dispatch(removeEstateManualInputDocument(id));
    };

    const openPreview = () => {
        authAPI.post('/single-token', {
            clientId,
            reviewer: estateManualInputRequest.reviewer,
            advisor: estateManualInputRequest.advisor,
            index: assetIndex
        }).then(response => {
            window.open(`${process.env.REACT_APP_FPALPHA_URL}/external-snapshot-preview?token=${response.data}&area=${area}`,'_blank')
        })
    }

    const HeaderOptions = () => {
        return (<div>
            {((estateManualInputRequest?.status === 'In review' && estateManualInputRequest.reviewer._id === me._id) || estateManualInputRequest?.status === 'Peer review') &&
                <div style={{textAlign: 'right', marginBottom: '1rem'}}>
                    <Button color='grey' onClick={saveForLaterBtn_Click}>Save for later</Button>
                    <Button style={{ paddingLeft: '50px', paddingRight: '50px' }} color='green' onClick={finishReviewBtn_Click}>Finish review</Button>
                </div>     
            }
            <div style={{textAlign: 'right', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-end', rowGap: '0.5rem'}}>
                <Button color='red' onClick={() => openPreview()}>
                    Preview
                </Button>
                <Button color='yellow' onClick={() => setShowPropertiesModal(true)}>
                    Add Properties
                </Button>
                {(estateManualInputRequest?.status !== 'Ready' || (estateManualInputRequest?.status === 'Ready' && me.permissions.includes('manual_inputs:open_documents'))) &&
                    <Button color='blue' onClick={() => window.open(`/dashboard/estate-manual-input/${clientId}/${area}/files`)}>
                        Open documents
                    </Button>
                }
            </div>
        </div>)
    }

    const saveForLaterBtn_Click = () => {
        dispatch(saveManualInputForLater(clientId, assetIndex, area));
    }

    const finishReviewBtn_Click = () => {
        const confirm = window.confirm('This action will set as concluded the reviewing phase. Do you want to confirm this action?');
        if (confirm) {
            dispatch(finishReview(clientId, assetIndex, area)).then(() => {
                setFinished(true);
            });
        }
    }

    if (finished) {
        return <Redirect to='/dashboard/manual-input' />
    }

    // console.log('TABS', tabs);
    // console.log('PANES', panes);
    // console.log('ASSETS MODAL:', showPropertiesModal);

    return (
        <DashboardTemplate scope="manual_input">
            <TabContainer
                title={area === 'home'
                    ? `Home Manual Input for ${estateManualInputRequest?.client?.displayName}`
                    : `Auto Manual Input for ${estateManualInputRequest?.client?.displayName}`}
                subtitle={`Form for ${area} documents.`}
                headerOptions={<HeaderOptions />}
            >
                <Grid>
                    <Grid.Column width={4}>
                        {panes.length > 0 && <Menu fluid vertical>
                            {panes.map(pane =>
                                <Menu.Item name={pane.menuItem} active={activeTab === pane.id} onClick={() => setActiveTab(pane.id)} style={{boxShadow: estateManualInputChangeDocuments && `${pane.id}` in estateManualInputChangeDocuments ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none'}}>
                                    {pane.menuItem}
                                    {pane.menuItem !== 'General' && <Icon name='delete' onClick={removeDocBtn_Click(pane.id)} />}
                                </Menu.Item>
                            )}
                        </Menu>}
                        {area === 'home' && <Button onClick={addHomeBtn_Click} style={{ marginBottom: '6px' }}>
                            Add Home
                        </Button>}
                        {area === 'auto' && <Button onClick={addAutoBtn_Click} style={{ marginBottom: '6px' }}>
                            Add Auto
                        </Button>}
                        {area === 'home' && <Button onClick={addUmbrellaBtn_Click} style={{ marginBottom: '6px' }}>
                            Add Umbrella
                        </Button>}
                    </Grid.Column>
                    <Grid.Column width={12}>
                        {panes.find(p => p.id === activeTab)?.render}
                    </Grid.Column>
                </Grid>
                {((estateManualInputRequest?.status === 'In review' && estateManualInputRequest.reviewer._id === me._id) || estateManualInputRequest?.status === 'Peer review') &&
                    <Container fluid textAlign='right' style={{ marginTop: '20px' }}>
                        <Button color='grey' onClick={saveForLaterBtn_Click}>Save for later</Button>
                        <Button style={{ paddingLeft: '50px', paddingRight: '50px' }} color='green' onClick={finishReviewBtn_Click}>Finish review</Button>
                    </Container>
                }
                {estateManualInputRequest?.status === 'Ready' &&
                    <Message color='green'>This HOME manual input has already been marked as REVIEWED.</Message>
                }
            </TabContainer>
            <FamilyMembersModal open={showFamilyModal} closeBtn_Click={() => setShowFamilyModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
            <FamilyMembersModal type={'trusts'} open={showEntitiesModal} closeBtn_Click={() => setShowEntitiesModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
            <PropertiesModal open={showPropertiesModal} closeBtn_Click={() => setShowPropertiesModal(false)} area={area} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
            <AssetsModal open={showAssetsModal} closeBtn_Click={() => setShowAssetsModal(false)} editingEnabled={estateManualInputRequest?.status === 'In review' || estateManualInputRequest?.status === 'Peer review'} />
        </DashboardTemplate>
    )
}